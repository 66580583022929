export const sliderService = {
    getDataSlider() {
        return [
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/mikels.jpg',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/1.png',
                alt: 'Description for Image 1',
                description: 'CERRADURA DEXTER D/SOBREPONER ELECTRICA C/BOTON DER BG',
                model: '2022',
                code: 'DEX2022',
                brand: 'Dexter',
                title: 'CERRADURA DEXTER',
                cost: 106.50,
                rating: 5,
                quantity: 0,
                category: 'Chapa',
                type: 'Mecanica',
                weight : '5kg',
                date: '11/11/2023',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/1.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/1.png',
                alt: 'Description for Image 1',
                description: 'CERRADURA DEXTER D/SOBREPONER ELECTRICA C/BOTON DER BG',
                model: '2022',
                code: 'DEX2022',
                brand: 'Dexter',
                title: 'CERRADURA DEXTER',
                cost: 100.50,
                rating: 5,
                quantity: 0,
                category: 'Chapa',
                type: 'Mecanica',
                weight : '5kg',
                date: '11/11/2023',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/2.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/11.png',
                alt: 'Description for Image 2',
                description: 'CORTADOR ANULAR   1-7/8" X 2"',
                model: 'INO4521',
                code: 'MILINO4521',
                brand: 'Milwaukee',
                title: 'CORTADOR ANULAR ',
                cost: 26.50,
                rating: 4,
                quantity: 100,
                category: 'Herramienta electrica',
                type: 'Inalambrica',
                weight : '2kg',
                date: '10/09/2023',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/3.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/21.png',
                alt: 'Description for Image 3',
                description: 'LLAVE DE IMPACTO COMPACTA 3/8" M12™ HERRAMIENTA SOLA',
                model: 'TRU 4562',
                code: 'TRU4562',
                brand: 'Truper',
                title: 'LLAVE DE IMPACTO',
                cost: 965.00,
                rating: 5,
                quantity: 150,
                category: 'Herramienta neumatica',
                type: 'Electrica',
                weight : '50kg',
                date: '10/05/2023',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/29.png',
                alt: 'Description for Image 4',
                description: 'LÁMPARA LED TIPO BULBO A19 9 W LUZ DE DÍA, CAJA, VOLTECK',
                model: 'BYP K45M',
                code: 'BYPK45M',
                brand: 'Dexter',
                title: 'LÁMPARA LED ',
                cost: 784.20,
                rating: 2,
                quantity: 50,
                category: 'Led',
                type: 'Manual',
                weight : '10kg',
                date: '05/10/2023',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/5.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/4.png',
                alt: 'Description for Image ',
                description: 'PINTURA EN AEROSOL, VERDE HOJA, BOTE TRADICIONAL, 400 ML',
                model: 'TUK 3CMJL',
                code: 'TUK3CMJL',
                brand: 'TUK',
                title: 'PINTURA EN AEROSOL',
                cost: 155.11,
                rating: 4,
                quantity: 10000,
                category: 'Pinturas',
                type: 'Electrica',
                weight : '7.5kg',
                date: '10/10/2023',
            },

            /*{
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/1.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/1.png',
                alt: 'Description for Image 1',
                description: 'CERRADURA DEXTER D/SOBREPONER ELECTRICA C/BOTON DER BG',
                model: '2022',
                title: 'CERRADURA DEXTER',
                cost: 496.50
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/2.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/11.png',
                alt: 'Description for Image 2',
                description: 'CORTADOR ANULAR   1-7/8" X 2"',
                model: 'INO4521',
                title: 'CORTADOR ANULAR ',
                cost: 26.50
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/3.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/21.png',
                alt: 'Description for Image 3',
                description: 'LLAVE DE IMPACTO COMPACTA 3/8" M12™ HERRAMIENTA SOLA',
                model: 'TRU 4562',
                title: 'LLAVE DE IMPACTO',
                cost: 965.00
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/29.png',
                alt: 'Description for Image 4',
                description: 'LÁMPARA LED TIPO BULBO A19 9 W LUZ DE DÍA, CAJA, VOLTECK',
                model: 'BYP K45M',
                title: 'LÁMPARA LED ',
                cost: 784.20
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/5.png',
                thumbnailImageSrc: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/marcas/4.png',
                alt: 'Description for Image ',
                description: 'PINTURA EN AEROSOL, VERDE HOJA, BOTE TRADICIONAL, 400 ML',
                model: 'TUK 3CMJL',
                title: 'PINTURA EN AEROSOL',
                cost: 155.11
            }*/
        ];
    },
    getDataCategory(){
        return[
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Abrasivos.png',
                title: 'Abrasivos',
                url  : '/Abrasivos',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Electrico1.png',
                title: 'Eléctrico',
                url  : '/cat/Electrico',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Electrica1.png',
                title: 'Eléctrica',
                url  : '/cat/Electrica',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Electricas1.png',
                title: 'Eléctricas',
                url  : '/cat/Electricas',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Ferreteria1.png',
                title: 'Ferretería',
                url  : '/cat/Ferreteria',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Jardineria1.png',
                title: 'Jardinería',
                url  : '/cat/Jardineria',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Manual1.png',
                title: 'Manuales',
                url  : '/cat/Manuales',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Pinturas1.png',
                title: 'Pinturas',
                url  : '/cat/Pinturas',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Plomeria1.png',
                title: 'Plomería',
                url  : '/cat/Plomeria',
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Proteccion1.png',
                title: 'Protección',
                url  : '/cat/Proteccion',
            },
        ];
    },
    getDataPrevBlog() {
        return [
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/5.png',
                title: '¿Cómo poner una repisa?',
                resume: 'En este blog se verá como instalar una repisa para nuestros libros.',
                url: 'como-poner-una-repisa',
                date: '02/11/2023',
                editor: 'PFG',
                category: ['"Repisa"', '"Hazlo usted mismo"', '"Taladro"'],
                comments: 5,
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/5.png',
                title: '¿Cómo poner una repisa?',
                resume: 'En este blog se verá como instalar una repisa para nuestros libros.',
                url: 'como-poner-una-repisa',
                date: '02/10/2023',
                editor: 'PFG',
                category: ['"Repisa"', '"Hazlo usted mismo"', '"Taladro"'],
                comments: 5,
            },
            {
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/Categorias/Abrasivos.png',
                title: '¿Cómo poner una repisa?',
                resume: 'En este blog se verá como instalar una repisa para nuestros libros.',
                url: 'como-poner-una-repisa',
                date: '02/11/2023',
                editor: 'PFG',
                category: ['"Repisa"', '"Hazlo usted mismo"', '"Taladro"'],
                comments: 5,
            },
        ]
    },
    getDataBlogDetail() {
        return [
            {
                next:'¿Cómo poner una ferretería?',
                previous:'¿Para qué sirve el WD-40?',
                image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/2.png',
                title: '¿Cómo poner una repisa?',
                date: '02/11/2023',
                editor: 'PFG',
                category: ['"Repisa"', '"Hazlo usted mismo"', '"Taladro"'],
                tools :[
                    {
                        tool: `Rotomartillo`,
                        brand: 'Urrea',
                        code: 'RM812E'
                    },
                    {
                        tool: 'Taladro',
                        brand: 'Urrea',
                        code: 'TE938'
                    },
                    
                    'Broca de concreto de ¼”', 'Broca multipropósitos de 3/32” (si la repisa necesita perforarse)','Taquetes plásticos de ¼”', 'Pijas de tablaroca de 1 ¼”', 'Pijas que no excedan el espesor de la repisa', 'Flexómetro', 'Nivel', 'Lápiz', 'Cinta de aislar', 'Desarmador'],
                materials: ['Repisa', 'Par de ménsulas'],
                steps:[
                    {
                        image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                        step: 'Paso 1',
                        text: 'El primer paso para saber cómo instalar repisas con escuadras es determinar el lugar en el que colocarás la repisa y asegurarte de que por el lugar no pasen instalaciones eléctricas o hidráulicas.',
                    },
                    {
                        image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                        step: 'Paso 2',
                        text: 'Marca en la repisa la altura a la que colocarás las ménsulas. Te recomendamos hacerlo a ¼ de la longitud total de tu repisa midiendo desde cada extremo hacia dentro.',
                    },
                    {
                        image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                        step: 'Paso 3',
                        text: `
                        Con el lápiz, haz una marca a la altura a la que deseas instalar la repisa. Coloca la tabla en la marca y utiliza el nivelador para asegurarte que esté completamente horizontal. Usa la cara inferior de la repisa para dibujar en el muro una línea que servirá como base para definir dónde fijarás los soportes.
                        Mide con el flexómetro un cuarto de la longitud total de tu repisa desde cada extremo de la línea hacia dentro, para que coincidan con los puntos que marcaste en la madera en el paso anterior.
                        Coloca la ménsula en una de las marcas que dibujaste con la cara más larga hacia el muro, nivélala y marca los puntos que deberás taladrar. Realiza el mismo procedimiento con la segunda ménsula.
                        `,
                    },
                    {
                        image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                        step: 'Paso 4',
                        
                        text:'Coloca la broca para concreto en el taladro, utiliza un taquete para marcar con la cinta de aislar la profundidad a la que deberás realizar la perforación. Realiza los agujeros en la pared y procede a colocar los taquetes en cada orificio. Fija las ménsulas atornillando las pijas a la pared.',
                    },
                    {
                        image: 'https://raw.githubusercontent.com/Proveedorferreterogdl/images/main/static/Images/slider/4.png',
                        step: 'Paso 5',
                        text: `

                        Coloca la repisa sobre las ménsulas asegurándote que las marcas que realizaste en el paso 2 coincidan con las del muro. Marca con el lápiz los orificios que perforarás en la repisa que servirán para fijarla a las ménsulas. Utiliza la broca para madera para realizar las perforaciones teniendo cuidado de no traspasar por completo la madera.
                        
                        Vuelve a colocar la repisa sobre las ménsulas y atorníllala utilizando el desarmador.
                        `,
                    },
                ]
            }
        ]
    },
    getDatajUser(){
        return[
            {
                name: 'Proveedor',
                lastname: 'Ferretero',
                birth: '29/11/1999',
                date: '29/11/2023',
                sale: 15,
                email: 'desarrollo.pfg@proveedorferretero.net',
                image: 'http://localhost:8080/img/logo.a96211e2.svg',
                cover: 'http://localhost:8080/img/logo.a96211e2.svg',
            }
        ]
    },
    getDataAddress(){
        return[
            {
                id: 1,
                street: 'Dr. R. Michel',
                numext: '3101',
                numint: '',
                col: 'Alamo Insustrial',
                cp: '45500',
                muni: 'San Pedro Tlaquepaque',
                estado: 'Jalisco',
                id_user: 1,
                num_address: 1
            },
            {
                id: 10,
                street: 'Calzada Independencia Sur',
                numext: '902',
                numint: '20',
                col: 'Mexicaltzingo',
                cp: '44180',
                muni: 'Guadalajara',
                estado: 'Jalisco',
                id_user: 1,
                num_address: 2
            },
            {
                id: 25,
                street: 'Av. Niños Heroes',
                numext: '610',
                numint: '',
                col: 'Alamo Industrial',
                cp: '45567',
                muni: 'San Pedro Tlaquepaque',
                estado: 'Jalisco',
                id_user: 1,
                num_address: 3
            }
        ]
    },
    getAllDetailCategory(){
        return Promise.resolve(this.getDataCategory());
    },
    getBlogDetail(){
        return Promise.resolve(this.getDataBlogDetail());
    },
    getBlogs(){
        const vari = Promise.resolve(this.getDataPrevBlog());
        
        return vari;
    },
    getImagesSlider() {
        const vari = Promise.resolve(this.getDataSlider());
        
        return vari;
    },
    getDataUser(){
        return Promise.resolve(this.getDatajUser());
    },
    getAllAddress(){
        return Promise.resolve(this.getDataAddress());
    }
};

