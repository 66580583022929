<template>
    <BannerBlue title="Preguntas frecuentes"/>
    <div class="container mt-5 faqs" style="align-items: center;">
        <center>
        <div style=" max-width: 600px;" class="mt-3">
            <div>
                <div v-for="(item, index) in items" :key="index" @click="toggleAccordion(index)" class="accordion-item">
                    <div class="accordion-header">
                        <span style="text-align: start;">{{ item.title }}</span>
                        <i :class="['accordion-icon', {'active': item.open}]"><i class="pi pi-angle-down"></i></i>
                    </div>
                    <div v-show="item.open" class="accordion-content">
                        <p v-html="item.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </center>
    </div>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
export default {
    components:{
        BannerBlue,
    },
    data() {
    return {
        items: [
            {   
                title: '¿Necesitas ayuda?', 
                content: `<div class="help">
                        <p class="accordionText">
                            En <strong>Proveedor Ferretero de Guadalajara</strong> estámos dispuestos a ayudarte. Contactate con nosotoros por cualquiera de nuestros medios.
                        </p>
                        <div >
                            <a href="https://www.facebook.com/proveedorferreterogdl/"><i class="pi pi-facebook"></i> Proveedor Ferretero GDL</a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/proveedorferreterogdl/"><i class="pi pi-instagram"></i> @proveedorferreterogdl</a>
                        </div>
                        <div>
                            <a href="https://wa.me/3315874072"><i class="pi pi-whatsapp"></i> 33 1587 4072</a>
                        </div>
                        <div>
                            <a href="tel:+5213315780535"><i class="pi pi-whatsapp"></i> 33 1578 0535</a>
                        </div>
                        <div>
                            <a href="mailto:contacto@proveedorferretero.net"><i class="pi pi-send"></i> contacto@proveedorferretero.net</a>
                        </div>
                    </div>`, 
                open: false 
            },
            { 
                title: '¿Cómo obtener mi factura?', 
                content: `<p class="accordionText">
                        Mandanos un correo al <a href="mailto:facturacion.proveedorferretero.net">facturacion.proveedorferretero.net</a> donde venga tu número de "SO" (Orden de venta) y tus datos fiscales. <br> Al recibir tu información, nuestro equipo de facturación tramitará la factura y te la hará llegar por correo electrónico a la brevedad posible.
                    </p>`, 
                open: false 
            },
            { 
                title: '¿Qué hago si no recibí el número de SO (Órden de Compra)?', 
                content: `<p class="accordionText">
                        1.- Revisa tu buzón de correos no deseados
                    </p>
                    <p class="accordionText">
                        2.- Si no lo encuentras por favor comunícate por correo a <a href="mailto:contacto@proveedorferretero.net">contacto@proveedorferretero.net</a> o por teléfono <a href="tel:+5213315780535">33 1578 0535</a>
                    </p>`, 
                open: false 
            },
            { 
                title: '¿Cómo puedo corregir mi factura?', 
                content: `
                    <p class="accordionText">
                        Envía un correo a <a href="mailto:contabilidad@proveedorferretero.net">contabilidad@proveedorferretero.net</a> solicitando corrección de la factura adjuntando los datos correctos.
                    </p>`, 
                open: false 
            },
            {
                title: 'No encuentro el material que busco en la página y requiero cotización',
                content: `
                <p class="accordionText">Contamos con un catálogo de más de 100,000 artículos, de los cuales no todos están en la página, por lo que si te interesa preguntar por algo que no encuentres, puedes comunicarte con nosotros al número <a href="tel:3315780535" style="text-decoration: underline">33 1578 0535</a> y pedir te pasen a un vendedor, también puedes ubicar tu sucursal más cercana, llamar a esta o visitarla para que te atiendan personalmente.</a>`,
                open: false
            },
            {
                title: '¿Cuánto tiempo tengo para emitir una factura?',
                content: `
                <p class="accordionText">Deberás emitir la factura dentro del mes en general que se realizó la compra. De lo contrario el pedido se facturará a "Público en general", lo cual implica llevará datos fiscales genéricos.</a>`,
                open: false
            },
        ]
        };
    },
    methods: {
        toggleAccordion(index) {
            this.items[index].open = !this.items[index].open;
        }
    }
}
</script>

<style scoped>
.accordionText{
    padding: 10px;
    color: #000;
}

.help div a{
    color: #000;
}

.help div a:hover{
    color: #ffbf00;
}

.accordion-item {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.accordion-header {
    cursor: pointer;
    font-size: 20px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.accordion-icon {
    transition: transform 0.3s;
}

.accordion-icon.active {
    transform: rotate(180deg);
}

.accordion-content {
    padding: 10px;
    
    text-align: start;
}
</style>