<template>
    <div style="background: #273644;">
        <img src="../assets/img/404.png" alt="404" style="width: 80%;"><br>
        <router-link to="/" class="mb-3 btn" style="color: white;"><span>Inicio</span></router-link>
    </div>
</template>


<script>
    export default {
        
    }
</script>

<style scoped>

</style>