<template>
    <br>
    <div class="centerDiv mt-2 mb-4" v-show="screenWidth > 1024">
        <div class="loginDiv" style="width: 60%;">
            <div class="left-div" :class="{ 'grid-left-active': isGridLeftActive, 'right-div-image': isGridLeftActive, 'left-div-image': !isGridLeftActive, 'grid-left' : true}" >
            </div>
            <div class="right-div" :class="{ 'grid-right-active': isGridLeftActive, 'grid-right':true }">
                <div class="divLogin-form" v-show="!isGridLeftActive"><!-- Div para iniciar sesion-->
                    <div style="height: 10%;">
                        <h3>Bienvenid@ de vuelta</h3>
                    </div>
                    <div style="height: 70%;" class="mt-3 d-flex flex-column justify-content-center ">
                        <label class="text-start">Correo*</label>
                        <div class="input-group mb-2">
                            <input required v-model="jUserLog.email" type="email" class="form-control" placeholder="Correo" aria-label="Username" aria-describedby="basic-addon1" style="height: 50px; width: 80%;">
                            <span class="input-group-text" id="basic-addon1" style="height: 50px;"><i class="pi pi-user" style="color: #000;"></i></span>
                        </div>
                        <label class="text-start">Contraseña*</label>
                        <div class="input-group mb-3">
                            <input v-model="jUserLog.password" :type="typePass" class="form-control" placeholder="Contraseña" aria-label="Username" aria-describedby="basic-addon1" style="height: 50px; width: 80%;">
                            <span class="input-group-text pass" @click="showPassF" id="basic-addon1" style="height: 50px;"><i :class="{'pi':true,'pi-eye':!showPass, 'pi-eye-slash': showPass}" style="color: #000;"></i></span>
                        </div>
                    </div>
                    <div style="height: 20%;">
                        <div>
                            <a class="btn" @click="login">Iniciar Sesión</a>
                        </div>
                        <div class="mt-3 mb-2">
                            <span>¿No tienes cuenta? <a class="a-link" style="cursor: pointer;" @click="swapGrids">Registrate aquí</a></span>
                        </div>
                    </div>
                </div>

                <div class="divLogin-form" v-show="isGridLeftActive"><!-- Div para Registrarse-->
                    <div style="height: 10%;">
                        <h3>únete</h3>
                    </div>
                    <div style="height: 70%;" class="mt-3 d-flex flex-column justify-content-center ">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="ficalle" placeholder="Calle" v-model="jUserReg.name">
                                    <label for="ficalle">*Nombre(s)</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="ficalle" placeholder="Calle" v-model="jUserReg.lastname">
                                    <label for="ficalle">*Apellido(s)</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-floating mb-3">
                                    <input type="number"  @input="validateInput" maxlength="10" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.phone" >
                                    <label for="ficalle">*Teléfono</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.email">
                                    <label for="ficalle">*Correo</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-floating mb-3">
                                    <input :type="typePass" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.pass">
                                    <label for="ficalle">*Contraseña</label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-floating mb-2">
                                    <input :type="typePass" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.rPass">
                                    <label for="ficalle">*Repetir Contraseña</label>
                                </div>
                            </div>
                            <span class="text-start" @click="showPassF" style="cursor: pointer; color: #000;">
                                <i :class="{'pi':true,'pi-eye':!showPass, 'pi-eye-slash': showPass}" style="color: #000;" ></i> <span v-show="!showPass">Mostarar contraseñas</span><span v-show="showPass">Ocultar contraseñas</span>
                            </span>
                        </div>
                    </div>
                    <div style="max-height: 20%;">
                        <div>
                            <a class="btn" @click="register()">crear cuenta</a>
                        </div>
                        <div class="mt-3 mb-2">
                            <span>Ya tengo una <a class="a-link" style="cursor: pointer;" @click="swapGrids">cuenta</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    <div v-show="screenWidth < 1024">
        <div class="card" style="margin: 10px; border: 1px solid #ffbf00;">
            <div class="card-body" v-show="!isGridLeftActive"> <!--Iniciar sesion cel-->
                <div style="height: 10%;">
                    <h3>Bienvenid@ de vuelta</h3>
                </div>
                <div style="height: 70%;" class="mt-3 d-flex flex-column justify-content-center ">
                    <label class="text-start">Correo*</label>
                    <div class="input-group mb-2">
                        <input required v-model="jUserLog.email" type="email" class="form-control" placeholder="Correo" aria-label="Username" aria-describedby="basic-addon1" style="height: 50px; width: 80%;">
                        <span class="input-group-text" id="basic-addon1" style="height: 50px;"><i class="pi pi-user" style="color: #000;"></i></span>
                    </div>
                    <label class="text-start">Contraseña*</label>
                    <div class="input-group mb-3">
                        <input v-model="jUserLog.password" :type="typePass" class="form-control" placeholder="Contraseña" aria-label="Username" aria-describedby="basic-addon1" style="height: 50px; width: 80%;">
                        <span class="input-group-text pass" @click="showPassF" id="basic-addon1" style="height: 50px;"><i :class="{'pi':true,'pi-eye':!showPass, 'pi-eye-slash': showPass}" style="color: #000;"></i></span>
                    </div>
                </div>
                <div style="height: 20%;">
                    <div>
                        <a class="btn" @click="login">Iniciar Sesión</a>
                    </div>
                    <div class="mt-3 mb-2">
                        <span>¿No tienes cuenta? <a class="a-link" style="cursor: pointer;" @click="swapGrids">Registrate aquí</a></span>
                    </div>
                </div>
            </div>
            <div class="card-body" v-show="isGridLeftActive"> <!--Registrarse cel-->
                <div style="height: 10%;">
                    <h3>únete</h3>
                </div>
                <div style="height: 70%;" class="mt-3 d-flex flex-column justify-content-center ">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Calle" v-model="jUserReg.name">
                                <label for="ficalle">*Nombre(s)</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Calle" v-model="jUserReg.lastname">
                                <label for="ficalle">*Apellido(s)</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-3">
                                <input type="number"  @input="validateInput" maxlength="10" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.phone" >
                                <label for="ficalle">*Teléfono</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.email">
                                <label for="ficalle">*Correo</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-3">
                                <input :type="typePass" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.pass">
                                <label for="ficalle">*Contraseña</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-2">
                                <input :type="typePass" class="form-control" id="ficalle" placeholder="Colonia" v-model="jUserReg.rPass">
                                <label for="ficalle">*Repetir Contraseña</label>
                            </div>
                        </div>
                        <span class="text-start" @click="showPassF" style="cursor: pointer; color: #000;">
                            <i :class="{'pi':true,'pi-eye':!showPass, 'pi-eye-slash': showPass}" style="color: #000;" ></i> <span v-show="!showPass">Mostarar contraseñas</span><span v-show="showPass">Ocultar contraseñas</span>
                        </span>
                    </div>
                </div>
                <div style="max-height: 20%;">
                    <div>
                        <a class="btn" @click="register()">crear cuenta</a>
                    </div>
                    <div class="mt-3 mb-2">
                        <span>Ya tengo una <a class="a-link" style="cursor: pointer;" @click="swapGrids">cuenta</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    props:{},
    components:{},
    data(){
        return{
            jUserLog: {
                password:'',
                email: ''
            },
            jUserReg: {
                name: '',
                lastname: '',
                phone: '',
                email: '',
                pass: '',
                rPass: '',
            },
            jAlert: [
                {
                    icon: 'error',
                    title:'Correo y/o contraseña incorrecta',
                    showConfirmButton:false,
                    timer:2000
                },
                {
                    icon: 'error',
                    title:'Por favor llene todos los campos',
                    showConfirmButton:false,
                    timer:2000
                },
                {
                    icon: 'error',
                    title:'Las contraseñas no coinciden',
                    showConfirmButton:false,
                    timer:2000
                }
            ],
            posAlert: 0,
            logreg_visible: false,
            showPass: false,
            typePass: 'password',
            btn_message: ['Registrate', 'iniciar Sesión'],
            isLeftDivHidden: true,
            isRightDivFull: false,
            isGridLeftActive: false,
            screenWidth: 0,
			screenHeight: 0,
        };
    },
    mounted: function(){
        if (localStorage.juserl) {//Verificamos que existe el objeto juserl
            if((localStorage.juserl).length > 0) this.$router.push({ path: '/perfil', replace: true })//Sino manda a iniciar sesión
			this.jUser = localStorage.juserl;
			this.$store.commit('setMyVariable', JSON.parse(this.jUser));
		}
        this.updateScreenSize();
		window.addEventListener("resize", this.updateScreenSize);
    },
    methods:{
        toUp(){//Mandar hasta arriba
			window.scrollTo(0, 0);
		},
        updateScreenSize() {//Actualizar el tamaño de la pantalla
			this.screenWidth = window.innerWidth;
			this.screenHeight = window.innerHeight;
			//this.cantCart = (this.$store.getters.getProducts).length;
		},
        alertM(index){
            Swal.fire({
                icon: this.jAlert[index].icon,
                title: this.jAlert[index].title,
                showConfirmButton: this.jAlert[index].showConfirmButton,
                timer: this.jAlert[index].timer
            });
        },
        validateVoid(jObject){//Validar si se encuentra vacio un elemento
            for (let key in jObject) {
                if (jObject[key] == '') {
                    this.posAlert = 1;//Posicion del json del mensaje de error
                    return false; // Si encuentra al menos un valor no vacío, retorna falso
                }
            }
            return this.validateEmail(jObject.email); // Todos los valores son vacíos
        },
        cleanVariable(jObject){//Funcion para limpiar los json
            for (let key in jObject) {
                jObject[key] = '';
            }
        },
        validatePass(pass, rPass){return pass == rPass ? true:false;},//Validar si las contraseñas son iguales
        validateEmail(email){//Validar que el correo tenga un @ y un .
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.posAlert = emailRegex.test(email)?1:0;//Posicion del json del mensaje de error
            return emailRegex.test(email);
        },
        validateInput(event) {
            const input = event.target;
            // Eliminar caracteres no numéricos y signos, permitiendo solo números enteros positivos.
            const sanitizedValue = input.value.replace(/[^0-9]/g, '');
            input.value = sanitizedValue;
        },
        login(){
            if(this.validateVoid(this.jUserLog)){
                console.log("Si");
            }else{
                this.alertM(this.posAlert)
                this.cleanVariable(this.jUserLog);
            }
            
        },
        register(){
            if(this.validateVoid(this.jUserReg)){
                if(this.validatePass(this.jUserReg.pass, this.jUserReg.rPass)){
                    //Mandar a api 
                }else{
                    this.alertM(2);//Llamada a la alerta 2
                }
            }else{
                this.alertM(this.posAlert)
                this.cleanVariable(this.jUserReg);
            }
        },
        showPassF(){
            this.showPass = !this.showPass;
            if(this.showPass) this.typePass = 'text'
            else this.typePass = 'password'
        },
        swapGrids() {
            this.toUp();
            this.isGridLeftActive = !this.isGridLeftActive;
        },
        toggleDivs() {
            this.isLeftDivHidden = !this.isLeftDivHidden;
            this.isRightDivFull = !this.isRightDivFull;
        },
    },
}
</script>

<style scoped>
.a-link{
    color: #ffbf00;
}
.a-link:hover{
    color: #000;
}
.pass:hover{
    cursor: pointer;
    background: rgba(171, 171, 171,0.2);
    color: #000;
}
.divLogin-form{
    height: 100%;
    
}
.grid-container {
    display: flex;
}
.grid-left, .grid-right {
    flex: 1;
    transition: transform 0.5s ease;
}

.grid-left-active {
    transform: translateX(100%);
}

.grid-right-active {
    transform: translateX(-100%);
}
.centerDiv{
    display: flex;
    justify-content: center;
}
.left-div-image{
    background-image: url("../assets/img/login.jpg");
    background-position: center; /* Centra la imagen horizontal y verticalmente */
    background-repeat: no-repeat;
    background-size: auto;
    animation: bgi 5s;
    border-radius: 10px 0px 0px 10px;
}
.right-div-image{
    background-image: url("../assets/img/signup.jpg");
    background-position: center; /* Centra la imagen horizontal y verticalmente */
    background-repeat: no-repeat;
    background-size: auto; 
    animation: bgi 5s;
    border-radius: 0px 10px 10px 0px;
}

@keyframes bgi {
    from {opacity:0.02}
    to {opacity:1}
}
.left-div-hidden {
    transform: translateX(-100%);
}

.right-div-full {
    transform: translateX(0);
}
.loginDiv {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.left-div img {
    max-width: 100%;
    height: auto;
}
.left-div{
    height: 608px;
    z-index: 99;
}
.right-div {
    z-index: 1;
    padding: 20px;
}

.right-div label {
    display: block;
    margin-bottom: 5px;
}

.right-div input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.right-div button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

@media screen and (max-width: 1020px) {
    .grid-left{
        height: 800px;
        display: none;
    }
    .right-div {
        min-height: 500px;
    }
    .grid-right-active {
        transform: translateX(0%);
    }
}

</style>