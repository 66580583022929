<template>
    <section class="s-header-title">
        <div class="container">
            <h1>{{ title }}</h1>
        </div>
    </section>
</template>

<script>
    export default {
        props:{
            title: String
        }
    }
</script>

<style lang="scss" scoped>

</style>