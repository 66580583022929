<template>
    <BannerBlue :title="BlogNameBlue"/>
    <div v-show="blogname === 'blog'">
        <Blog/>
    </div>
    <div v-show="blogname != 'blog'">
        <BlogDetail :blogname="blogname"/>
    </div>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import Blog from '@/components/Blog.vue';
import BlogDetail from '@/components/BlogDetail.vue';
import { ref } from 'vue';
export default {
    props:{},
    components:{
        BannerBlue,
        Blog,
        BlogDetail,
        
    },
    data(){
        return{
            blogname : ref('blog'),
            BlogNameBlue: ref('blog')
        };
    },
    created(){
        if(this.$route.params.title != null){
            this.BlogNameBlue = (this.$route.params.title).replaceAll("-"," ");
            this.blogname = this.$route.params.title;
        } 
    },
    methods:{},
}
</script>

<style  scoped>

</style>