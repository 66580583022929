export const DateService = {
    async update(data) {
        for(var i = 0; i < data.length; i++){
            var parts = data[i].date.split('-');
            data[i].date = `${parts[2]}/${parts[1]}/${parts[0]}`;
        }
        return data;
        
    },
    //Funcion para cambiar el formato de impresión de la fecha de yyyy-mm-dd a dd/mm/yyyy
    updateDate(data) {
        return Promise.resolve(this.update(data));
    },
}