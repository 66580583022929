<template>
    <div class="container">
        <div class="overlay15">
            <div class="exitM" style="right:0; margin-top: 10px;"><br>
                <span style="font-size: 23px;" @click="ocModal" class="btnClose">
                    X
                </span>
            </div>
            <div style="display: flex; justify-content: center;">
                <div class="containerSearch" style="display: block;">
                    <h3 class="mb-2">Dirección {{ index }}</h3>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Calle" v-model="jAddressM.street">
                                <label for="ficalle">*Calle</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Colonia" v-model="jAddressM.numext">
                                <label for="ficalle">*Num. Ext.</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Colonia" v-model="jAddressM.numint">
                                <label for="ficalle">Num. Int.</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Colonia" v-model="jAddressM.cp">
                                <label for="ficalle">*C.P.</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example" :disabled="colShow">
                                    <option selected>Seleccione la colonia</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <label for="floatingSelect">Colonia</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Colonia" disabled>
                                <label for="ficalle">*Municipio</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="ficalle" placeholder="Colonia" disabled>
                                <label for="ficalle">*Estado</label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-floating">
                                <textarea class="form-control" placeholder="Caracteristicas" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Caracteristicas</label>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: end; " class="mt-2">
                        <button class="my-button-cancel">Cancelar</button>
                        <button class="my-button-save">Guardar</button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>

import { sliderService } from '@/services/sliderService';
import {ref, onMounted, onBeforeUnmount} from 'vue';
    export default {
        name: 'ModalSV',
        props:{
            jAddressM: Object,
            index: Number,
        },
        components:{
        },
        data(){
            return{
                valor : ref(false),
                vSearch: ref(),
                resSearch: ref(),
                visibleSearch: ref(false),
                colShow: true,
            }
        },
        watch:{
            'jAddressM.cp': function(newData, oldData){
                console.log((this.jAddressM.cp).length);
                this.colShow = (this.jAddressM.cp).length > 0 ? false : true;
            }
        },
        methods: {
            ocModal() {
                this.$emit("cerrarModal", this.valor); // Emitir un evento hacia el componente padre
                //sintaxis, ("nombre-de-funcion", variable-a-enviar)
            },
            resultsSear(){
                if(this.vSearch != ""){
                    this.visibleSearch = true;
                    sliderService.getImagesSlider().then((data) => (this.resSearch = data));
                }else{
                    this.resSearch = null;
                    this.visibleSearch = false;
                }
            },
        },
    }
</script>

<style scoped>
.my-button-save, .my-button-cancel {
    padding: 10px 20px;
    background-color: white;
    color: #000;
    border: none;
    cursor: pointer;
    margin-left: 5px;
}

.my-button-cancel:hover{
    color: #616161;
    border-bottom: 1px solid #000;
}

.my-button-save{
    background-color: #ffbf00;
    border-radius: 5px;
}

.my-button-save:hover {
    background-color: #e2aa00;
}

.my-button-save:active {
    background-color: #e9af01;
}
.btn:hover{
    cursor: pointer;
    color: #fff;
}
.resultsSearch{
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.tResSearch tr{
    border-bottom: 1px dotted #00000086;
}
.v-enter-active, .v-leave-active {
    transition: opacity 0.5s ease;
}
.v-enter-from, .v-leave-to {
    opacity: 0;
}
input {
    padding: 10px 30px;
}
.exitM{
    width: 96%;
    height: auto;
    display: flex; /* Utiliza un contenedor flexbox */
    justify-content: flex-end; 
}
.btnClose{
    width: 35px;
    height: 35px;
    font-size: 1rem; 
    cursor: pointer;
    background: rgba(255, 255, 255, 0.5);
    color: #000;
    border-radius: 100%;
}
.btnClose:hover{
    background: rgba(255, 255, 255, 1);
}
.containerSearch{
    display: block;
    width: 70%;
    border-radius: 20px;
    padding-left: 3rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex; /* Utiliza un contenedor flexbox */
    justify-content: center;
    background: #fff;
    color: #000;
}
.overlay15 {
	position: fixed; /* O puedes usar 'absolute' según tus necesidades */
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7); /* Fondo oscuro semitransparente */
	z-index: 9999; /* Z-index alto para que se superponga a otros elementos */
	color: white; /* Color de texto, ajusta según tus necesidades */
	text-align: center;
}
.input-container {
    display: flex;
    align-jAddressMs: center;
    width: 100%;
}

.input-container span {
  padding: 0.5em; /* Ajusta el espaciado según tus necesidades */
}

.input-container input {
  flex: 1; /* El input toma el espacio restante */
}
@media only screen and (max-width: 750px) {
    .containerSearch{
        padding-left: 15px;
        padding-right: 15px;
    }
}
</style>