<template>
    <div style="background: #273644;">
        <img src="../assets/img/mantenimiento.png" alt="404" style="width: 80%;"><br>
        <span style="color: white; font-size: 28px;">Por favor intente más tarde.</span><br>
        <button 
            class="btnGen mb-2"
            @click="$router.push('/')"
            style="background-color: #fff;"
        > 
        Inicio
            <i class="pi pi-home" style="color: #000;"></i>
        </button>
    </div>
</template>


<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>