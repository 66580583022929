<template>
    <BannerBlue :title="'TÉRMINOS Y CONDICIONES'"/>
    <div class="container">
        <div class="" style="background-color: #fff;border-radius: 10px 10px 0 0;">
            <div class="" style="padding: 2.5rem;">
                <p class="terminos">Guadalajara, Jalisco a 18 de Agosto de 2023.</p>
                <p class="mt-5 terminos">
                    Bienvenido al Sitio de Internet de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> en la dirección <router-link to="/">https://www.proveedorferreterogdl.com/</router-link> 
                    le sugerimos que lea cuidadosamente las siguientes condiciones (en adelante "Términos") antes de navegar y/o hacer uso de cualquier 
                    parte de este sitio web (en adelante "Sitio"). <router-link to="/">https://www.proveedorferreterogdl.com/</router-link> (en adelante " <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> ") 
                    se reserva el derecho a modificar el presente documento bajo las circunstancias que más le convengan y sin previo aviso, siendo 
                    estos cambios vigentes al momento mismo de su publicación, así mismo aplicables a todas las visitas al Sitio, al contenido y 
                    servicios que se proporcionan en él o a través de él. Si existe alguna duda por favor comunícate con nosotros por Contacto, a los 
                    teléfonos que aparecen al final de la página al igual que el correo de contacto. Al acceder al Sitio usted (en adelante "Usuario") 
                    declara que es mayor de 18 años y que está de acuerdo con todos los Términos y las demás leyes y/o reglamentaciones aplicables al 
                    Sitio. Si no está de acuerdo totalmente con el mismo, por favor le invitamos a abandonar el sitio.
                </p>
                <h2 class="mt-5">CONDICIONES DE USO</h2>
                <p class="mt-5 terminos">
                    Con el objetivo de brindarle al USUARIO una interfaz segura y agradable, hemos establecido las siguientes Condiciones de Uso. 
                    Todos los materiales presentados en el SITIO incluyendo imágenes, textos, ilustraciones, identidad gráfica, fotografías, programas, 
                    clips de música, audio, video clips y otros materiales multimedia que son parte del mismo (colectivamente, los "Contenidos") están 
                    destinados exclusivamente para uso personal, más no comercial. El USUARIO puede descargar o copiar los Contenidos y demás material 
                    descargable presentado en el SITIO únicamente para uso personal, que como resultado no otorga al USUARIO NINGUN derecho, título o 
                    interés sobre dicho material derivado de las descarga, copia o transmisión de los mismos. El USUARIO no puede reproducir (a excepción 
                    para los fines establecidos en renglones anteriores), publicar, transmitir, distribuir, exhibir, modificar, crear tareas derivadas de, 
                    vender o participar en la venta de o aprovechar de ningún modo, total o parcialmente, cualquiera de los Contenidos presentados en el 
                    SITIO o cualquier software relacionado. Todo Software y los Contenidos son propiedad de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> o de 
                    cualquiera de sus proveedores y está protegido por las leyes del país, así como por las leyes Internacionales de derecho de autor. 
                    Los Contenidos y el software presentados en el SITIO pueden ser utilizados exclusivamente como recurso para Comprar y/o Adquirir los 
                    productos y/o servicios de este Sitio. Cualquier otro uso distinto incluyendo la reproducción, modificación, distribución, transmisión, 
                    re publicación, exhibición o ejecución de los Contenidos de este SITIO queda estrictamente prohibido. El incumplimiento a lo descrito 
                    otorga a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> el derecho y facultad para exigir al Usuario el pago de cualesquiera daños y perjuicios que 
                    por dicho incumplimiento hubiese sufrido o deba sufrir <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> independiente de las acciones penales, civiles 
                    y mercantiles que procedan conforma a la legislación vigente aplicable. Algunos de los servicios y las características proporcionados en 
                    el Sitio pueden requerir de un previo registro o suscripción, por lo que si el USUARIO decide registrarse o suscribirse en cualquiera de 
                    los servicios o funciones relacionadas, el USUARIO se compromete a proporcionar información precisa, actualizada y verídica, acerca del 
                    USUARIO mismo, con la responsabilidad de actualizar su información, así toda vez sea necesario para tener los datos más actuales en caso 
                    de existir algún cambio. Cada USUARIO está obligado a mantener resguardados sus contraseñas, claves y todo identificador pertinente de 
                    cuentas seguras proporcionado por el Sitio. El titular de la cuenta generada en el Sitio es totalmente responsable de todas las 
                    actividades y movimientos que se generen bajo su contraseña y cuenta. Adicionalmente el USUARIO está obligado a notificar a Proveedor 
                    Ferretero de Guadalajara, cuando se detecte cualquier uso no autorizado de cuenta y contraseña, tomando en consideración, que de ninguna 
                    manera <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se responsabilizará directa o indirectamente por cualquier movimiento en la cuenta, merma o 
                    daño de cualquier tipo incurridos como resultado de la pérdida, robo o clonación de datos del USUARIO. El USUARIO deberá tomar en 
                    consideración que su cuenta puede ser restringida o cancelada por cualquier razón a la exclusiva discreción de Proveedor Ferretero de 
                    Guadalajara. De igual manera <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se reserva el derecho a su exclusiva discreción de prohibir ventas, 
                    cancelar pedidos o toda acción necesaria cuando se detecten malas prácticas en la cuenta del USUARIO o por cualquier otra razón. Para el 
                    caso de sitios de terceros, las referencias que puedan encontrarse en este sitio de cualquier nombre, marca, producto o servicio de 
                    terceros, así como los enlaces de hipertexto a un sitio tercero o información, son proporcionados únicamente como conveniencia para el 
                    USUARIO y de ninguna forma constituye o implica que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> aprueba, patrocina o recomienda al tercero, su 
                    información, productos o servicio. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no es en ningún momento responsable de las prácticas y políticas, 
                    ni del contenido, ni de ningún sitio de terceros, y no hace ninguna representación en relación a los productos y servicios de terceros, o 
                    el contenido o precisión de cualquier material en tales sitios de terceros. Si el USUARIO decide enlazarse a un sitio de terceros, lo 
                    hace bajo su propio riesgo y responsabilidad, de igual manera si el USUARIO decide enlazarse a un sitio tercero deberá considerar que el 
                    uso de dicho sitio estará regulado por los términos y condiciones, así como demás políticas de cobro, uso y revelación de la información 
                    personal del USUARIO que el propietario, operador o representante de dicho sitio establezcan. Por lo cual es responsabilidad única y 
                    exclusiva del USUARIO revisar los términos y condiciones antes de utilizar cualquier sitio tercero. 
                </p>
                <h2 class="mt-5">PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
                <p class="mt-5 terminos">
                    Todos los contenidos del Sitio, salvo que se exprese lo contrario, son titularidad exclusiva de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> o de 
                    terceros con previa autorización para su inclusión en el SITIO y con carácter enunciativo, no limitativo, el diseño gráfico, código 
                    fuente, logos, textos, gráficos, ilustraciones, fotografías, íconos, material escrito así como todos los elementos que aparecen en el 
                    SITIO, las marcas registradas y/o la característica intelectual poseída, controlada o licenciada por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, 
                    alguno de sus afiliados o por terceros que han licenciado sus materiales a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> y son protegidos por las 
                    leyes mexicanas y de Copyright Internacionales. La compilación total (entiéndase colección, arreglo o el armado del SITIO) de todo el 
                    contenido de este SITIO es exclusivo de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> y también es protegida por leyes mexicanas y de Copyright 
                    Internacionales. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no concede ningún tipo de licencia, título, interés o autorización de uso personal 
                    al USUARIO sobre los Derechos de Propiedad Intelectual e Industrial o sobre cualquier otro Derecho relacionado con el SITIO y los 
                    servicios y/o productos ofrecidos en el mismo, a excepción de los casos que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> manifieste lo contrario. 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, así como sus proveedores y otorgantes de licencias se reservan todos los derechos intelectuales en 
                    todo el texto, programas, productos, procesos, tecnología, accesos, contenido y otros materiales que aparecen y pudieran aparecer en 
                    este SITIO. El acceso a este sitio no otorga y no podrá considerarse de ninguna forma como una concesión a nadie de ninguna licencia de 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> o de ningún derecho de propiedad intelectual de ningún tercero. Los nombres y logos de Proveedor 
                    Ferretero de Guadalajara, así mismo todos los productos relacionados y nombres de servicios, marcas de diseño y eslóganes son las marcas 
                    registradas o marcas de servicio de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. Todas las otras marcas son propiedad de sus respectivas 
                    compañías. Ninguna licencia de marca registrada o marca de servicio tanto de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> o de terceros está 
                    concedida en relación a los materiales contenidos en este SITIO. El acceso a este sitio no autoriza ni concede utilizar cualquier nombre, 
                    logo o marca en ninguna forma. El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> y a acceder a los servicios del SITIO única y exclusivamente para su uso personal y privado, así como 
                    las disposiciones u objetivos que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> determine. El USUARIO reconoce que la reproducción total o parcial, 
                    la distribución sin previo consentimiento, la comercialización, transformación del contenido y en general, cualquier forma de explotación, 
                    por cualquier vía o procedimiento, de todo o parte de los contenidos de este SITIO, salvo los casos expresamente autorizados, constituye 
                    una infracción de los Derechos de Propiedad Intelectual y/o Industrial de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> o del titular de los mismos. 
                    Así mismo, el USUARIO, se deberá abstener de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de 
                    seguridad que estuviera instalado en el SITIO. SOBRE PRECIOS Y CONTENIDOS.
                </p>
                <h2 class="mt-5">SOBRE PRECIOS Y CONTENIDOS</h2>
                <p class="mt-5 terminos">
                    Los precios de los productos, servicios, promociones, disponibilidad y fechas de venta en este SITIO pueden diferir de los dados a conocer 
                    en sucursales de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, por lo que puede presentar variación de acuerdo a la región geográfica. Los precios 
                    se manejan en pesos mexicanos y para casos en que aplique en dólares (sujeto al tipo de cambio del día respecto a los pesos mexicanos). 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> busca proporcionar la información necesaria y precisa en los precios, sin embargo, pueden ocurrir 
                    errores tipográficos y de etiquetado, así como en las ilustraciones o anuncios presentados en el SITIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> 
                    no puede confirmar el precio de un artículo hasta que el mismo USUARIO realice su pedido. En dicho caso donde se manifieste un error en el 
                    precio del artículo, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> tendrá la facultad a su exclusiva discreción de rechazar o cancelar todo pedido 
                    de este artículo. <br>En <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> buscamos ser su mejor opción en compras ferreteras a través de nuestro SITIO como 
                    en nuestras sucursales. Sin embargo, en algunas ocasiones el precio en el SITIO, así mismo como las promociones aplicables, no son iguales 
                    en sucursal. Por lo cual los precios pueden diferir de los presentados en el SITIO. Nuestras sucursales no respetan, ni están sujetas a 
                    los precios publicados en el SITIO en internet, así mismo tampoco a precios derivados de comparaciones con anuncios de competidores en 
                    tiendas en línea, así como tiendas físicas dentro y fuera del territorio local de comercio de cualquiera de nuestras sucursales. Proveedor 
                    Ferretero de Guadalajara no realiza ningún tipo de ajuste basado en sucursales, otras tiendas físicas o en línea, o bien por petición 
                    personal de USUARIO. No obstante <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se esfuerza por mantener los precios altamente competitivos para 
                    tenerlos al alcance del USUARIO. Para la descripción de la mercancía, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> siempre trata de ser exacto y 
                    breve en las descripciones del producto de tal forma que el USUARIO comprenda la constitución, funcionalidad y características del mismo. 
                    <br>Sin embargo, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no garantiza que las descripciones del producto u otro contenido presentadas en el SITIO 
                    sean exactas, completas, fiables, actuales o libres de error. Por lo que el USUARIO tiene la responsabilidad de rectificar por otros 
                    medios que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> pone a su disposición, las características del producto de manera completa, antes de 
                    realizar cualquier pedido. Si en el caso de que los productos recibidos enviados al USUARIO no corresponden a las características 
                    descritas, la única solución es devolverlo, sin ser utilizado, sin haber sido manipulado y con su empaque y/o accesorios y/o manuales 
                    íntegros salvo análisis operativo una vez el producto en nuestras instalaciones. 
                </p>
                <h2 class="mt-5">ACEPTACIÓN DEL PEDIDO</h2>
                <p class="mt-5 terminos">
                    Para la comodidad del USUARIO, no se realiza ningún cargo hasta que la forma de pago sea autorizada. Algunas situaciones pueden resultar 
                    en la cancelación de pedido tales como la limitación en unidades solicitadas, imprecisiones o errores de la información o precio, 
                    problemas identificados que pudiesen presumirse como intento de fraude. También <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> contactará al USUARIO 
                    para solicitar verificaciones o información adicional antes de aceptar el pedido, para fines de asegurar el envío oportuno del producto y 
                    la recepción del (los) pago (pagos) correspondientes. Los pedidos se aceptarán únicamente por los medios dispuestos en el SITIO por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> o teléfono a través de un asesor, y serán vinculantes una vez que hayan sido aceptados por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> y se emita una Confirmación de Pedido al USUARIO a través de los medios dispuestos por el mismo (correo 
                    electrónico y teléfono). <br>El usuario por tanto está obligado a verificar la Confirmación emitida por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, y 
                    notificar en el inmediato por cualquiera de los medios de comunicación de servicio al cliente dispuestos por PROVEEDOR FERRETERO DE 
                    GUADALAJARA, cualquier error. En caso contrario, la descripción del (los) Producto(s) en la Confirmación de Pedido pasará a integrar este 
                    documento y será vinculante para las partes. Una vez concretado el pedido el USUARIO podrá seguir su pedido a través del enlace específico 
                    en este SITIO, de igual manera con la empresa de Paquetería por la que se le envió su pedido, también podrá hacerlo a través de los medios 
                    de atención al cliente dispuestos por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. 
                </p>
                <h2 class="mt-5">TÉRMINOS DE VENTA</h2>
                <p class="mt-5 terminos">
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> contactará con el cliente si la totalidad o parcialidad del pedido ha sido cancelado por errores en la 
                    publicación de precios o descripciones en el SITIO, o cuando ya no se tengan existencias de algún artículo del pedido, en caso de suceder 
                    después de concretado el pago (o realizado el cargo, por otros medios de pago), se emitirá un reembolso por el medio más cómodo para el 
                    cliente, o bien se emitirá un bono por el total del monto cancelado para futuras compras en el SITIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> 
                    puede negarse a procesar pedidos futuros de USUARIOS con un historial previo de pedidos fraudulentos, incluido el rechazo en pedidos 
                    asociados con una disputa previa, relacionada con una tarjeta de crédito o débito. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> enviará el pedido 
                    del USUARIO por el medio que el mismo haya seleccionado de las opciones que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> pone a su disposición. Toda 
                    información referente a dirección enviada a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> será considerada como válida y tratada como tal para el 
                    envío del pedido solicitado, siendo responsabilidad exclusiva del USUARIO asegurarse de que dicha información sea correcta y exacta. 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no se responsabiliza por errores en las direcciones proporcionadas por el USUARIO. El tiempo de entrega 
                    de los pedidos puede variar de acuerdo a la región donde resida el USUARIO de 3 a 5 días hábiles por envío terrestre en México y el costo 
                    puede ser cubierto por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> en los casos que así se determinen. El USUARIO podrá hacer uso de los envíos de 
                    día hábil siguiente vía aérea en México, pero el costo será cubierto por el mismo USUARIO. Esto sujeto a las disposiciones y políticas, 
                    así como la cobertura logística de las empresas con las que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> tenga convenio vigente, de entre las cuales 
                    el USUARIO haya elegido una, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> informará al USUARIO cuando la cobertura de la empresa de paquetería 
                    seleccionada esté limitada en la región de residencia del mismo, los costos de reexpedición, así como otras tarifas adicionales al envío 
                    que las empresas de paquetería tengan que cobrar para asegurar la entrega del paquete hasta la dirección indicada por el USUARIO, correrán 
                    a cargo exclusivamente por el mismo USUARIO, salvo aquellas excepciones en las que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> indique expresamente 
                    que asume los gastos generados, por conceptos de garantías, devoluciones o por cualquier otro concepto sujeto a previa aprobación. 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se reserva el derecho a su exclusiva discreción de negar o aceptar el cargo adicional de las empresas 
                    de paquetería para efectuar el envío en los casos mencionados anteriormente. Para envíos en CENTROAMÉRICA y SUDAMÉRICA estarán sujetos a 
                    la cobertura logística internacional y local de las empresas con las que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> tiene convenio vigente. El 
                    USUARIO acepta todo cargo adicional por concepto de pago aduanal, impuestos de importación y otros derivados de la legislación vigente en 
                    el país de residencia del USUARIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se limita al envío de los productos con las tarifas previamente 
                    acordadas (con el USUARIO y la empresa de paquetería), los tiempos de entrega del pedido pueden variar de acuerdo al tiempo del trámite 
                    administrativo, aduanal o todo aquel derivado de la legislación mexicana y del país de residencia del USUARIO, de igual manera estará 
                    sujeto a las tarifas adicionales que la empresa de paquetería disponga por conceptos de seguro, reenvíos, convenios con empresas de 
                    transporte adicionales y todos los trámites y/o recursos necesarios para garantizar al envío, los cuales serán cubiertos por el propio 
                    USUARIO. De ninguna forma <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> cubrirá importes fuera de México. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se 
                    compromete a tener al tanto al USUARIO sobre el estado de su pedido y envío dentro de México hasta su llegada en el país destino y con el 
                    USUARIO. Sin embargo, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no se hace responsable por trámites adicionales, retención de producto o 
                    cualquier otro contratiempo que se presente una vez que el producto salga de México, por lo que el USUARIO tiene la obligación de conocer 
                    los lineamientos y restricciones de su país para recibir producto del extranjero. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> limitará en medida de 
                    lo posible ofrecer y/o vender productos que puedan violar las leyes de países de CENTRO AMERICA y SUDAMERICA, más sin embargo no limitará 
                    su publicación en el SITIO al ser estos de "libre venta" en México, el USUARIO deberá informarse previamente en los medios de atención al 
                    cliente dispuestos por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, si el (los) producto(s) pueden ser enviados a su país de residencia, esto no 
                    obliga a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> a informar al USUARIO que productos pueden o no enviarse a su país. Cuando productos de un 
                    pedido se encuentren fuera de stock, el pedido estará "pendiente" a menos que el USUARIO decida hacer su cancelación o modificación. 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se compromete a enviarle el producto "pendiente" en un plazo no mayor a 30 días a partir de la fecha 
                    del pedido, en caso contrario, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> contactará al USUARIO a través de los medios de comunicación 
                    proporcionados por el mismo en su registro. No se realizará cobro o cargo por los productos faltantes de su pedido y su proporcional de 
                    gastos de manejo y envío (si esto aplica). 
                </p>
                <h2 class="mt-5">FORMAS DE PAGO</h2>
                <p class="mt-5 terminos">
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> ofrece diferentes opciones de pago que se adecuan a las necesidades del cliente. Nos reservamos el 
                    derecho a nuestra exclusiva discreción de cambiar las opciones de pago en cualquier momento y por cualquier motivo. MÉXICO: El USUARIO en 
                    México puede realizar el pago de su compra en las opciones a continuación listadas con total seguridad y confianza. Tarjeta de Débito o 
                    Crédito: El USUARIO podrá cubrir el monto total de su compra mediante tarjeta bancaria. El USUARIO deberá asegurarse de que la tarjeta 
                    esté vigente y haya sido emitida en México por cualquiera de las instituciones bancarias del país bajo la marca VISA, MASTERCARD, asimismo 
                    tendrá la obligación de asegurarse de contar con los fondos o límite de crédito suficiente para cubrir el monto total de su compra y que 
                    su tarjeta esté habilitada para compras en línea. Es importante señalar que no se admite pago con tarjetas American Express. PAYPAL: Como 
                    parte del servicio de compra en línea de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, el USUARIO podrá cubrir el monto total de su compra a través 
                    de PAYPAL. Para ello se requiere una cuenta registrada en PayPal y una tarjeta de crédito, débito o cuenta bancaria asociada a su cuenta 
                    de PayPal. Efectivo: <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> pone a disposición del USUARIO el servicio de pago en efectivo a través de Mercado 
                    pago o pago directo a nuestras cuentas bancarias. Pago en Banca por Internet: <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> a través del Servicio 
                    Mercado pago pone a disposición del USUARIO la posibilidad de realizar el pago del monto total de su compra a través del servicio de Banca 
                    por Internet de los bancos disponibles; el USUARIO también podrá pagar directamente a nuestras cuentas bancarias descargando el PDF en la 
                    sección de pago durante el proceso de compra. CONSIDERACIONES AL REALIZAR SU PAGO: En pagos con tarjeta de Crédito o Débito 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no almacena ningún dato de tarjeta bancaria como número, CVV, fecha de vencimiento, NIP, código 
                    Verified Visa o Mastercard Secure Code, etcétera. Al confirmar su compra el USUARIO es redirigido a un sitio web de un tercero contratado 
                    por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> para acreditar el pago, el USUARIO al hacer uso de dicho servicio acepta los Términos y 
                    Condiciones, así como el Aviso de Privacidad que rigen dichos sitios web, mismos que son ajenos a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> y su 
                    SITIO web. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no se responsabiliza por cualquier sobregiro o rechazo de transacción por medio de Tarjeta 
                    de Crédito o Débito generados por el banco emisor, ni por la aplicación de impuestos, comisiones y/o intereses adicionales, 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no participa en el cobro de ninguno de estos conceptos, consulte con su banco emisor las comisiones 
                    vigentes de su tarjeta o cuenta bancaria. El USUARIO al hacer uso de este servicio de pago deslinda a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> 
                    toda responsabilidad por el posible mal uso de sus tarjetas bancarias como resultado de un Robo o Extravío, de igual manera 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se deslinda por posibles fraudes o robo de datos bancarios que puedan cometerse por sitios de terceros 
                    accedidos a través del SITIO con los que no se tenga relación o convenio alguno. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no es responsable por 
                    saldos retenidos en su cuenta ajenos al pago de su compra, errores en los números de cuenta o cargos adicionales a su pago; es 
                    responsabilidad exclusiva del USUARIO asegurarse de la autenticidad de los sitios web de terceros accedidos desde este SITIO y de la 
                    información que el USUARIO proporcione en los mismos. Para aclaraciones el USUARIO deberá ponerse en contacto con su Institución Bancaria. 
                    En pagos a través de Banca por Internet, el total final de dicho movimiento estará sujeto a los impuestos y/o comisiones que aplique su 
                    entidad bancaria, en algunas entidades bancarias el uso del servicio de Banca por Internet puede tener un costo periódico o por movimiento. 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no almacena ni solicita números de token, NIP dinámico, ni cobra total o parcialmente ningún tipo de 
                    comisión o cargo adicional generado, por lo que es responsabilidad exclusiva del USUARIO informarse sobre cargos adicionales, impuestos 
                    y/o comisiones que puedan aplicar con su Institución Financiera, En pagos realizados a través de Paypal puede existir una demora de hasta 
                    24 horas en recibir la confirmación formal de pago por parte de Paypal a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, por lo que hasta recibir 
                    dicha confirmación, no se tomará como realizado el pago total, en algunos casos Paypal podrá pedirle información y/o documentación 
                    adicional para confirmar su identidad, misma que no tiene relación alguna con éste SITIO; al realizar el pago de su compra a través de 
                    Paypal con cargo a Tarjeta de Crédito o Débito, podrán reflejarse retenciones de saldo para confirmar que su tarjeta está activa para 
                    compras en línea, por lo cual el reembolso de dichas retenciones corre a cargo de Paypal y de su banco emisor; 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no es responsable de retención alguna, asociación de cuenta bancaria o tarjeta de crédito o débito a 
                    Paypal. Paypal es un sitio web de pago ajeno a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, por lo que el uso de su servicio se rige por sus 
                    términos y condiciones, así como su aviso de privacidad. 
                </p>
                <h2 class="mt-5">FACTURACIÓN</h2>
                <p class="mt-5 terminos">
                    En base a la Ley Federal de Protección de Datos Personales en Posesión de Particulares, aplicable en los Estados Unidos Mexicanos, toda 
                    información que el USUARIO facilita a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> por medio de este SITIO será usada con los fines que establece 
                    nuestra Aviso de Privacidad vigente mismo que el USUARIO puede consultar en este SITIO, cuando el USUARIO solicite la Facturación se 
                    compromete a brindar Información valida, completa y precisa. Por lo que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se limitará a facturar en base 
                    a la información recibida, siendo responsabilidad exclusiva del USUARIO verificarla y validarla antes de iniciar el proceso de solicitud. 
                    Por ningún motivo <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> realizará cambios en facturación a menos que se demuestre un error interno. Toda 
                    Factura solicitada deberá ser dentro del mismo mes en curso. Por ninguna razón procederán facturas de compras de meses anteriores. Si el 
                    USUARIO requiere de factura deberá solicitarla en la liga correspondiente en el SITIO o a través de los medios de atención al cliente que 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> disponga. Deberá tener a la mano la siguiente información: R.F.C. (incluida homoclave), Nombre Completo 
                    o Razón Social, Domicilio Fiscal (incluyendo Calle, Colonia, Delegación o municipio, estado y código postal), Forma en la que se realizó 
                    el pago (Depósito, Transferencia electrónica, Tarjeta de Crédito o Débito, Paypal), No. De pedido, e-mail (donde se hará llegar la factura) 
                    los datos proporcionados no son almacenados en el SITIO. 
                </p>
                <h2 class="mt-5">POLÍTICA DE CANCELACIONES, GARANTÍAS Y DEVOLUCIONES.</h2>
                <p class="mt-5 terminos">
                    CANCELACIONES: Una vez autorizado el pedido, el USUARIO tiene un máximo de 24 horas para cancelar o modificar cualquier pedido emitido 
                    bajo su Cuenta y Contraseña, para realizarlo tendrá que comunicarse a través de los medios de atención a clientes dispuestos por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, en caso de realizar su cancelación se le reintegrará el monto total menos el 10% correspondiente a 
                    gastos administrativos y de operación, el reembolso se hará a través del medio más cómodo para el cliente, en caso de que los artículos 
                    de la compra se hayan enviado o bien se haya excedido el periodo de 24 horas <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no podrá cancelar o 
                    modificar pedido alguno. Una vez que el pedido llegue a manos del USUARIO, es responsabilidad y obligación del mismo revisar las 
                    condiciones físicas y de funcionamiento del (los) producto (s) en el inmediato o durante el periodo definido por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, una vez transcurrido este periodo, se considera que el USUARIO ha aceptado el (los) producto (s). 
                    DEVOLUCIONES Y GARANTÍAS: Si el USUARIO adquirió un producto en este SITIO, podrá solicitar su devolución o garantía bajo las siguientes 
                    condiciones: <br>a) El producto presenta defectos de fabricación. <br>b) El producto que llegó al USUARIO no es el solicitado. <br><br>Para realizar el 
                    proceso deberá realizar su solicitud a través de la liga correspondiente en el sitio, o bien en los medios a atención a clientes 
                    dispuesto por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, para el reembolso del pedido el USUARIO deberá cumplir con las siguientes condiciones: 
                    Para devoluciones o cambios cuando el producto no corresponde al solicitado: El USUARIO deberá presentar la solicitud con un máximo de 7 
                    días hábiles una vez recibido el producto a través de los canales de atención a clientes dispuestos por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. Tener en óptimas condiciones el producto, junto con sus manuales, accesorios y todo contenido 
                    incluido en el empaque del mismo, así como la factura o nota que le fue enviada por correo electrónico o en el mismo producto, en 
                    promociones armadas será necesario enviar todo el conjunto del promocional. Una vez hecho el reporte correspondiente por cualquiera de 
                    los canales de atención al cliente proporcionado por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se deberá enviar el producto junto con su Nota o 
                    Factura a la dirección: Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500. Tlaquepaque Jalisco. El envío por parte del USUARIO del producto, 
                    así como la recepción del mismo por parte de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no garantiza la devolución o cambio hasta previo análisis correspondiente. 
                    El USUARIO acepta que una vez enviado el producto estará sujeto a revisión por el equipo técnico de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, quien analizará que el producto 
                    esté en las condiciones como se envió. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> en tanto se compromete a dar respuesta lo más pronto posible notificándose al cliente en todo 
                    momento el seguimiento a su producto y la resolución de éste a través de los medios de atención a clientes dispuestos por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. La devolución 
                    en los casos que aplique se hará por el medio más cómodo para el USUARIO, o en su defecto a través de bonos para sus futuras compras en el SITIO. 
                    PARA EL CASO DE CAMBIOS POR CONCEPTO DE GARANTÍA: El USUARIO deberá presentar la solicitud dentro del periodo estipulado de garantía una vez recibido el producto, a través de 
                    los canales de atención a clientes dispuestos por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. Tener en óptimas condiciones el producto, junto con sus manuales, accesorios y todo 
                    contenido incluido en el empaque del mismo, así como la factura o nota que le fue enviada por correo electrónico o en el producto mismo, en promociones armadas será necesario 
                    enviar todo el conjunto del promocional. Una vez hecho el reporte correspondiente por cualquiera de los canales de atención al cliente proporcionado por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. se deberá enviar el producto junto con su Nota o Factura a la dirección: Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500. 
                    Tlaquepaque Jalisco. El envío por parte del USUARIO del producto, así como la recepción del mismo por parte de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no garantiza la aplicación 
                    de la garantía válida hasta previo análisis correspondiente. En los casos de la devolución por cambio o garantía el USUARIO acepta que una vez enviado el producto estará sujeto 
                    a revisión por el equipo técnico de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, quien analizará que el producto se haya operado de acuerdo a las especificaciones del manual de usuario, 
                    así mismo la integridad de los materiales, circuitos y demás partes que puedan constituir al producto. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> en tanto se compromete a dar respuesta 
                    lo más pronto posible notificándose al cliente en todo momento el seguimiento a su producto y la resolución de éste a través de los medios de atención a clientes dispuestos por 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. Las revisiones técnicas del producto estarán sujetas a la aprobación del fabricante por lo que la cantidad de marcas y productos es limitada, 
                    sugerimos consultar la cobertura de nuestro servicio técnico. Como parte del servicio de este SITIO, todo producto que incluya una garantía y aquel que así sea especificado, 
                    goza de una cobertura directa con <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> de 30 días naturales, transcurridos éstos el USUARIO es responsable absoluto de reclamar la garantía con el 
                    fabricante y/o representante de marca en su región más cercano, de acuerdo a lo especificado en la póliza individual del producto enviado. En garantías, cuando aplique, se 
                    realiza el cambio del producto por uno nuevo, sin embargo, no existen reembolsos de ningún tipo. Para el caso que así se indique <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. absorberá 
                    los gastos de envío realizados por el cliente cuando de garantías válidas se trate (sujeto a disponibilidad) o cuando las devoluciones estén justificadas, en caso contrario el 
                    USUARIO es el único responsable de los gastos de envío generados. Para los productos ofrecidos como oferta o liquidación, éstos poseen sólo una garantía limitada y es válida 
                    directamente con el fabricante, salvo casos donde se especifique su validez también con <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, de la misma forma estos productos no son sujetos de 
                    cambio o devoluciones, por ser productos ofrecidos como tal en liquidación o saldos. 
                </p>
                <h2 class="mt-5">POLÍTICA DE PRIVACIDAD</h2>
                <p class="mt-5 terminos">
                    Los datos personales que el USUARIO ha facilitado a este SITIO se tratan conforme al Aviso de Privacidad contenido en -------- 
                </p>
                <h2 class="mt-5">JURISDICCIÓN</h2>
                <p class="mt-5 terminos">
                    Este documento queda sometido a las leyes de los Estados Unidos mexicanos. Cualquier controversia será sometido a los tribunales del estado de JALISCO, tanto 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> como el USUARIO renuncian expresamente cualquier otro fuero que pudiera corresponderles en razón de su domicilio presente o futuro. 
                </p>
                <h2 class="mt-5">TERMINACIÓN.</h2>
                <p class="mt-5 terminos">
                    Estos TÉRMINOS son efectivos y válidos a menos que y hasta que sean rescindidos por el USUARIO. De igual manera <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> puede poner fin a la 
                    cuenta del USUARIO, así como su acceso al SITIO sin previo aviso y a la exclusiva discreción de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> por motivos legítimos que incluyen, 
                    entre otros, si el USUARIO no cumple con estos TÉRMINOS, en cuyo caso el USUARIO está obligado a destruir todos los materiales descargados u obtenidos de este SITIO, así 
                    mismo todas las copias de dichos materiales, que se hicieron bajo estos TÉRMINOS u otra manera. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> podrá exigir la ejecución de la 
                    obligación del USUARIO de rescindir estos TÉRMINOS también podrá exigir resarcimiento de daños y perjuicios, de los que fuere objeto <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. 
                    Ultima modificación 02 de marzo de 2022. Al hacer clic en esta casilla acepto que deslindo por completo a la empresa <strong>PROVEEDOR FERRETERO DE GUADALAJARA S. A. de C. V. </strong>
                </p>
                <h2 class="mt-5">POLITICA DE PRIVACIDAD DATOS PERSONALES</h2>
                <p class="mt-5 terminos">
                    FP-PO-DI-03 En cumplimiento a la Ley Federal de protección de Datos Personales en Posesión de Particulares, <strong>PROVEEDOR FERRETERO DE GUADALAJARA S. A. de C. V. </strong>, mejor conocido como 
                    PROVEEDOR FERRETERO GDL, con domicilio en Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500. Tlaquepaque jalisco, y portal de internet 
                    <router-link to="/">https://www.proveedorferreterogdl.com/</router-link> es el responsable del uso y protección de sus datos personales. Los datos personales que aquí recopilamos los destinamos para los 
                    siguientes propósitos: Registro de Usuario y contraseña para compras en nuestro sitio. Fines de Identificación y verificación para la compra en nuestro sitio. Contacto para 
                    seguimiento a sus compras y atención a sus inquietudes. Ofrecerle y entregarle nuestros bienes y servicios. Identificación de su historial de compras. De manera adicional, 
                    utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan brindarle 
                    una mejor atención: Fines Estadísticos sobre las compras realizadas en nuestro sitio. Mercadotecnia o publicidad de productos que ofrecemos y pueden ser de su interés. 
                    Prospección comercial para clientes potenciales y nuevos mercados. Como parte de nuestro servicio y en la búsqueda de los objetivos de venta y comercialización de nuestros bienes 
                    en este sitio web, <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no solicita datos sensibles de ningún tipo ni a través de otros medios como teléfono o correo electrónico. Usted podrá 
                    contactarnos en cualquier momento a través de nuestro correo electrónico servicioalcliente@proveedorferretero.net por teléfono al 01 (33) 1578-0535, (33) 1578-0536 número 
                    gratuito o directamente en nuestras oficinas ubicadas en Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500 Tlaquepaque jalisco La negativa para el uso de sus datos 
                    personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros. <br><br>¿QUÉ DATOS PERSONALES UTILIZAREMOS PARA ESTOS FINES? 
                    <br>Los datos que usted libre y voluntariamente proporcione a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> a través de este sitio, mediante la utilización de nuestros servicios en línea, 
                    son los siguientes: Razón Social Forma de pago Método de pago Datos de tarjeta Nombre completo RFC Teléfonos Domicilio personal Domicilio fiscal Dirección de correo electrónico 
                    <br><br>¿PARA QUÉ UTILIZAMOS ESTOS DATOS? <br>Los datos personales recopilados de usuarios que se registran y acceden a los servicios de internet que aquí ofrecemos, se destinan 
                    únicamente para los propósitos siguientes: Generar registro de Usuario y Contraseña para compras en nuestro sitio. Fines de identificación y/o verificación, para usuarios 
                    registrados en nuestro sitio. Contacto para orientación al cliente y seguimiento de sus compras. Realizar compras en línea de bienes y servicios que 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> comercializa a través de su página en Internet y por teléfono. Envío de mercancías adquiridas que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> 
                    comercializa en la página de internet y por teléfono. Asistencia telefónica o en línea por correo electrónico. Informar al cliente sobre el estatus de sus compras. Enviar 
                    información (newsletter o boletín) sobre productos nuevos que pueden ser de su interés. Identificar su historial de compras. Sus datos personales serán tratados con base a los 
                    principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en términos de la Legislación. Se mantendrá la 
                    confidencialidad de sus datos personales estableciendo y manteniendo de forma efectiva las medidas de seguridad administrativas, técnicas y físicas, para así evitar su daño, 
                    pérdida, alteración, destrucción, uso, acceso o divulgación indebida. Sus datos personales proporcionados en este sitio viajan a través de Internet de manera segura y 
                    confidencial. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> emplea tecnología de criptografía SSL (Secure Sockets Layer) de comunicación segura, con la cual sus datos son transferidos de 
                    manera autentificada y de forma privada desde su equipo hasta nuestros servidores, lo que garantiza la protección de su información. SOBRE LA DIVULGACIÓN DE SU INFORMACIÓN. 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> podrá celebrar contrato con uno o varios terceros, mismo en el que se incluirán cláusulas de confidencialidad y protección de datos personales, 
                    esto como proveedores de servicios como apoyo a las actividades de promoción y comercialización de nuestros bienes y servicios, así como el manejo y administración de los datos 
                    personales que se recaban a través de este sitio y llamadas telefónicas, así como cualquier otro medio distinto, por lo que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> podría transferir 
                    sus datos personales a dichos terceros sin un fin comercial, ni ilícito, sólo con el afán de cumplir con los servicios contratados. En ningún caso, comercializaremos, venderemos 
                    o rentaremos información personal sobre usted a un tercero sin el consentimiento previo de su parte. Cualquier transferencia de información que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> 
                    realice, será únicamente para los fines contemplados y establecidos en las Leyes. Se entiende que ha manifestado su consentimiento a la transferencia de sus datos personales si 
                    no se manifiesta oposición para ello. Los datos que usted proporcione a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> se podrán compilar en una base de datos de propiedad y para uso 
                    exclusivo de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, por lo que toda liga de acceso a sitios de terceros no son responsabilidad de <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, entendiendo 
                    que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no asumirá responsabilidad alguna respecto al contenido y políticas de privacidad en dichos sitios. Con objeto de que usted pueda limitar 
                    el uso y divulgación de su información personal, le ofrecemos los siguientes medios: Realizando solicitud al número al 01 (33) 1578-0535, (33) 1578-0536 número gratuito o a 
                    través de la dirección de correo electrónico servicioalcliente@proveedorferretero.net <br><br>¿CÓMO PUEDE ACCEDER, RECTIFICAR O CANCELAR SUS DATOS PERSONALES, U OPONERSE A SU USO? <br>
                    Como titular de datos personales usted tiene derecho a conocer cuáles datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). 
                    Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de 
                    nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para 
                    fines específicos (Oposición). Estos derechos se conocen como derechos ARCO. Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva 
                    a través de la dirección de correo electrónico servicioalcliente@proveedorferretero.net Para ejercer sus derechos ARCO la solicitud a presentar deberá contener por lo menos los 
                    siguientes requisitos: Nombre completo y domicilio u otro medio para comunicarle la respuesta a su solicitud. Documentos que acrediten su identidad o, si es el caso, la 
                    representación legal. La descripción clara y precisa de los datos personales respecto de los que se solicita ejercer alguno de los derechos ARCO. Manifestación expresa de revocar 
                    su consentimiento al tratamiento de sus datos personales y por consiguiente que estos no sean utilizados. Cualquier otro elemento que facilite la localización de sus datos 
                    personales. La petición también deberá ir acompañada de los fundamentos por los que se solicita dicha revocación y una identificación oficial del titular de los datos o de su 
                    apoderado. En un plazo máximo de 20 días hábiles atenderemos la solicitud y le informaremos sobre la procedencia de la misma a través del correo electrónico del que provenga la 
                    petición. Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que 
                    no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos 
                    personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, 
                    o la conclusión de su relación con nosotros. La forma en que podrá revocar el consentimiento para el tratamiento de sus datos personales es presentando su solicitud a través de 
                    nuestro Departamento de privacidad, cuya información de contacto se presenta a continuación: Departamento de Atención al Cliente, que está a cargo de dar trámite a las 
                    solicitudes de derechos ARCO, cuya información de contacto para atender las solicitudes sobre el tratamiento de sus datos personales es la siguiente: <br>a) Nombre: Departamento 
                    de Privacidad <br>b) Domicilio: Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500 Tlaquepaque Jalisco. <br>c) Correo electrónico: servicioalcliente@proveedorferretero.net 
                    <br>d) Número telefónico: 01 (33) 1578-0535, (33) 1578-0536 número gratuito. <br><strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> le solicita al usuario que realice la actualización de sus 
                    datos, cada vez que haya lugar alguna modificación, de esta manera <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> podrá brindarle al usuario un servicio eficiente y personalizado. 
                    En términos de la LFPDPPP usted podrá iniciar el procedimiento de protección de derechos ARCO ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de 
                    Datos Personales a los 15 días siguientes de qué <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> diera respuesta a su solicitud o bien si transcurrido el tiempo que señala la Ley, 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no diera respuesta a su solicitud. EL USO DE TECNOLOGÍAS DE RASTREO EN NUESTRO PORTAL DE INTERNET Le informamos que en nuestro sitio de 
                    internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor 
                    servicio y experiencia al navegar en nuestro sitio. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines: Para administrar 
                    y operar los servicios de venta que usted realiza con nosotros. Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes: Identificadores, nombre de 
                    usuario y contraseñas de una sesión Región en la que se encuentra el usuario Tipo de navegador del usuario Tipo de sistema operativo del usuario Fecha y hora del inicio y final 
                    de una sesión de un usuario Búsquedas realizadas por un usuario Publicidad revisada por un usuario La aceptación de las cookies no es un requisito para visitar nuestro sitio de 
                    internet. Sin embargo, es importante señalar que el uso del sistema de compras (carrito de compras o bolsa de compras) funcionalidad de la página, es posible con la activación 
                    de las cookies SOBRE LAS COOKIES Las cookies son pequeños archivos generados por el navegador web del usuario y que se alojan en el disco duro de la computadora, sirven para 
                    identificar a la computadora como usuario único en nuestros servidores cuando se ingresa a ciertas páginas de nuestro sitio. Las cookies se pueden utilizar para reconocer la 
                    dirección de protocolo de internet de la computadora del usuario, que ayuda a ahorrar tiempo mientras se quiera ingresar a nuestro sitio. Las cookies se utilizan para facilitar 
                    la navegación del usuario en nuestro sitio (por ejemplo, recordar a un usuario registrado sin necesidad de volver a introducir los campos del inicio de sesión) y no para obtener 
                    o usar cualquier información sobre el usuario. El navegador desde el cual se accede a nuestro sitio puede ser configurado para no aceptar cookies, pero ello implicaría restringir 
                    el uso de nuestro sitio. Para llevar a cabo dicha configuración será necesario revisar la sección de ayuda del navegador del usuario, desde el cual también se podrá revisar cómo 
                    evitar aceptar nuevas cookies, desactivar todas las cookies y cómo hacer que el navegador le notifique cuando recibe una nueva cookie. Para más información sobre las cookies 
                    puede visitar <a href="http://www.allaboutcookies.org/es/">http://www.allaboutcookies.org/es/</a> <br><br>¿CÓMO PUEDE CONOCER LOS CAMBIOS EN ESTE AVISO DE PRIVACIDAD? <br>El presente aviso de privacidad puede sufrir modificaciones, 
                    cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de 
                    privacidad; de cambios en nuestro modelo de negocio, o por otras causas, por lo cual nos reservamos el derecho de cambiar el presente Aviso de Privacidad en cualquier momento. 
                    Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de nuestro sitio <strong>PROVEEDOR FERRETERO DE GUADALAJARA S. A. de C. V. </strong> 
                    El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad será con una alerta al ingresar a nuestro 
                    sitio. SU CONSENTIMIENTO PARA EL TRATAMIENTO DE SUS DATOS PERSONALES Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en 
                    el presente aviso de privacidad. AUTORIDAD. Si el usuario considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene el derecho de acudir 
                    ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales que es la autoridad correspondiente para defender su ejercicio, su sitio web 
                    es: <a href="http://www.ifai.gob.mx">http://www.ifai.gob.mx</a> ACEPTACIÓN DE LOS TÉRMINOS. Esta declaración de privacidad está sujeta a los términos y condiciones del sitio web de 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> antes descrito, mismo que constituye un acuerdo legal entre el usuario y <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. Si el usuario utiliza cualquiera 
                    de los servicios de este sitio, el usuario manifiesta que ha leído, entendido y acordado los términos antes descritos. Última modificación: 02 de marzo de 2022 POLÍTICAS DE 
                    VENTA La información presentada en este sitio de internet tiene fines informativos, puede consultar la información completa en TÉRMINOS Y CONDICIONES visitando 
                    <strong>PROVEEDOR FERRETERO DE GUADALAJARA S. A. de C. V. </strong>, en caso de requerir también puede comunicarse con nosotros en los medios dispuestos en el apartado ATENCIÓN AL CLIENTE o 
                    mediante nuestro formulario de contacto. HORARIOS DE OPERACIÓN El sitio opera las 24 horas del día los 365 días del año, sin embargo, la operación de sus pedidos está sujeta a 
                    los horarios de operación de nuestros almacenes y centros operativos que es de lunes a viernes 8:30 a 18:30 horas y sábado de 8:30 a 18:30 horas, excepto días feriados oficiales. 
                    COBERTURA DE VENTA La cobertura de venta de este SITIO está limitada a las siguientes regiones: México. México: La cobertura de venta aplica dentro del territorio mexicano 
                    exclusivamente, sin embargo, algunas poblaciones pueden estar limitadas debido a su ubicación geográfica, por ello sugerimos consultar con el asesor de venta la cobertura 
                    disponible. Los precios se fijarán en pesos mexicanos (MXN). El SITIO puede ser consultado desde cualquier parte del mundo, sin embargo, en regiones diferentes a las enunciadas 
                    anteriormente estará limitado a consulta informativa. POLÍTICA DE DEVOLUCIONES Y GARANTÍAS Cancelaciones: Si el USUARIO requiere alguna cancelación o modificación de sus pedidos 
                    podrá realizarla en un plazo máximo de 24 horas después de haber recibido la confirmación del pedido, este periodo es único e inapelable y sólo lo podrá realizar el USUARIO que 
                    generó dicho pedido a través de su cuenta y contraseña. En el caso de cancelaciones se realizará el reembolso del monto total, menos el 10% por concepto de gastos administrativos 
                    y de operación. Posterior a las 24 horas o una vez enviado el producto, no se modificará o cancelará pedido alguno sin excepciones. Artículos faltantes: De acuerdo a la cantidad 
                    de artículos solicitados en su pedido, estos pueden enviarse en diferentes paquetes (sin implicar gastos adicionales), en cuyo caso el USUARIO podrá; rastrearlos por separado, se 
                    puede consultar el estatus del envío a través de los medios de atención al cliente dispuestos por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> mediante la cuenta de usuario en este SITIO, 
                    así como la página correspondiente a la empresa de paquetería seleccionada. Estos datos los recibirá previamente el cliente. En caso de que se envíen pedidos parciales, se le 
                    dará a conocer al USUARIO a través de los medios de contacto proporcionados, por lo que solo se cobrará el importe del producto enviado (y su equivalente en gastos de envíos). 
                    El USUARIO podrá cancelar el pedido pendiente, por lo que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> realizará el reembolso. Producto no es el solicitado: El USUARIO tendrá hasta 7 días 
                    hábiles para realizar su solicitud, el producto deberá estar en perfectas condiciones e ir acompañado de todos sus accesorios, documentación (incluida factura y/o nota), empaques 
                    y etiquetas. El USUARIO deberá enviar el artículo a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> a la dirección Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500 Tlaquepaque 
                    Jalisco. El envío corre a cargo del USUARIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> recibirá el producto y realizará un análisis previo al reembolso, para garantizar la integridad 
                    del producto. Cambios por concepto de garantía: El USUARIO tendrá hasta 30 días hábiles a partir de la fecha de recepción del producto para realizar la solicitud de cambio por 
                    garantía, para lo cual el USUARIO deberá comunicarse a través de los canales de contacto disponibles y posteriormente enviar el producto con sus accesorios, documentación 
                    (incluida factura y/o nota), empaques y etiquetas a la dirección Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500 Tlaquepaque Jalisco. El envío corre a cargo del 
                    USUARIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> recibirá el producto y realizará un análisis para valorar si procede la garantía, en concepto de garantía no existen reembolsos de 
                    ningún tipo. En <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> la garantía puede estar extendida a 30 días con nosotros y también directamente en sucursal, transcurrido el periodo el usuario 
                    deberá reclamar la garantía directamente con el fabricante. Consulte con su asesor el periodo de garantía aplicable, en algunos productos presentados como oferta pueden tener 
                    una garantía más limitada, debido a que se presentan como productos en saldo o remate. Los reembolsos se realizarán por el medio más cómodo para el cliente o a través de nota de 
                    crédito para futuras compras en el SITIO. Para clientes de Centroamérica y Sudamérica esta política puede variar, sugerimos consultar con su asesor los periodos para hacer 
                    válidos los cambios y garantías. FORMAS DE PAGO <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> pone a su disposición diferentes formas de pago: México: Pueden realizarse mediante las 
                    siguientes opciones: Pago con cargo a Tarjeta de Crédito y de Débito (VISA Y MASTERCARD), Pago en Efectivo, Pago a través de banca por Internet y Paypal. Centroamérica y 
                    Sudamérica: Sólo se podrá realizar a través de la plataforma de pago de PAYPAL. Notas: Las entidades bancarias podrían cobrar comisiones y/o honorarios por el uso de sus 
                    servicios, por ello sugerimos que consulte con su banco las posibles tarifas aplicables. En ninguna transacción <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> cobra total o parcialmente 
                    alguna comisión efectuada por las entidades bancarias. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no almacena en ninguna forma claves, NIP, números de cuentas bancarias o de tarjetas, 
                    por lo que no se responsabiliza por el daño total o parcial derivado por el mal uso, robo, clonación, o todo acto de perjuicio al titular de la información enunciada. El pago 
                    con cargo a Tarjeta de Débito o Crédito es proporcionado por Pagos Web, subsidiaria de Códice, su uso se rige por los términos y condiciones de este sitio ajeno a nuestra 
                    empresa. El servicio Pago en Efectivo y a través de Banca por Internet es proporcionado por Safetypay, su uso está sometido a los términos y condiciones de este sitio ajeno a 
                    nuestra empresa. PAYPAL es un sistema ajeno a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>, por lo cual su uso está sometido a los términos y condiciones de este sitio ajeno a nuestra 
                    empresa. Para mayor información visite nuestra sección de Términos y Condiciones en <strong>PROVEEDOR FERRETERO DE GUADALAJARA S. A. de C. V. </strong> POLÍTICAS DE ENVÍO <br>1) Tiempo de entrega total 
                    para un pedido en México. El tiempo de entrega de los pedidos tendrá variación respecto al área geográfica donde resida el USUARIO, así como las condiciones y políticas propias 
                    de las empresas de paquetería con la que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> tenga convenios vigentes. Procesado de pedido: El procesado del pedido puede tardar de 1 a 2 días de 
                    acuerdo a la cantidad de productos que conformen el pedido, la disponibilidad y el volumen de los mismos, éste es el tiempo en que se genera la orden y que el producto sale de 
                    nuestros almacenes. Si el pedido y respectivo pago del USUARIO es confirmado antes de las 16:00 horas su envío podrá ser canalizado el día hábil siguiente (dependiendo de la 
                    cantidad de artículos solicitados). Entrega estimada: Depende del tipo de entrega, este tiempo se cuenta a partir de que el producto sale de nuestros almacenes y llega hasta 
                    su domicilio: Entrega inmediata: al día siguiente. Estándar: de 3 a 5 días hábiles. Los costos de envío serán cubiertos por el USUARIO, a excepción de aquellos donde se exprese 
                    que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> los cubrirá, sin embargo el USUARIO debe considerar que los gastos adicionales como reexpedición, así como los que apliquen por políticas 
                    de las empresas de paquetería, deberán ser cubiertos por el propio usuario. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> hace envíos a toda la república mexicana, sin embargo están sujetos 
                    a la cobertura logística de la empresa de paquetería externa en cuyo caso, se le notificará al USUARIO cuando la cobertura esté limitada y/o restringida en su región de 
                    residencia. Es importante destacar que en <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> no recibe pagos contra entregas. <br>2)Tiempo de entrega total para un pedido en Centroamérica y 
                    Sudamérica. El tiempo de entrega de los pedidos puede variar de un país a otro así como de la ubicación geográfica donde resida el USUARIO, de igual manera estará sujeto a 
                    las condiciones y políticas de las empresas de paquetería con las que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> tenga convenios vigentes. El tiempo estimado para que el USUARIO reciba 
                    su pedido puede variar también de acuerdo a las condiciones legales del país de destino, por ello le sugerimos al USUARIO revisar las legislaciones vigentes aplicables en su 
                    lugar de residencia. El USUARIO recibirá un tiempo de entrega estimado, sin embargo éste no es exacto por lo que el envío puede llegar antes o después de la fecha estimada. <br>De 
                    igual manera todos los costos de envío adicionales que pudieran cobrar las empresas de paquetería, por conceptos de reexpedición, y/o todas aquellas que se requieran para 
                    asegurar el envío al USUARIO, serán cubiertas por el USUARIO. ATENCIÓN AL CLIENTE Para brindarle un mejor servicio ponemos a su disposición los siguientes medios de contacto 
                    desde los cuales podrá, hacer llegar sus comentarios, sugerencias o aclaraciones. México: Nuestra línea telefónica sin costo es 01 800 231 0000, está a su disposición de lunes a 
                    viernes de 9:00 a 18:00 horas y sábados de 9:00 a 16:00 horas. También podrá contactarse a través del Chat en línea, por correo electrónico a través de 
                    servicioalcliente@proveedorferretero.net o en el apartado Contáctanos en la sección AYUDA. El USUARIO podrá contactar a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> a través del CHAT en 
                    línea disponible en el SITIO, por correo electrónico en contacto@proveedorferretero.net o a través de nuestro número telefónico +52 01 (238) 38 322 72 de lunes a viernes de 9:00 
                    a 18:00 horas, sábados de 9:00 a 16:00 horas (UTC -06:00, Hora Central Guadalajara, Ciudad de México y Monterrey). POLÍTICAS DE COMPRA Conozca las políticas y procesos de compra 
                    que deberá considerar una vez que ha decidido adquirir un producto en <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. <br>POLÍTICAS DE COMPRA La información presentada en este sitio de internet 
                    tiene fines informativos, puede consultar la información completa en TÉRMINOS Y CONDICIONES visitando <router-link to="/terminos-y-condiciones">https://www.proveedorferreterogdl.com/terminos-y-condiciones</router-link>, en caso de 
                    requerirlo también puede comunicarse con nosotros en los medios dispuestos en el apartado ATENCION AL CLIENTE o mediante nuestro formulario de contacto. HORARIOS DE OPERACIÓN El 
                    sitio opera las 24 horas del día los 365 días del año, sin embargo la operación de sus pedidos está sujeta a los horarios de operación de nuestros almacenes y centros operativos 
                    que es de lunes a sábado de 9:00 a 18:00 horas, excepto días feriados oficiales. COBERTURA DE VENTA La cobertura de venta de este SITIO está limitada a las siguientes regiones: 
                    México, México: La cobertura de venta aplica dentro del territorio mexicano exclusivamente, sin embargo, algunas poblaciones pueden estar limitadas debido a su ubicación 
                    geográfica, por ello sugerimos consultar con el asesor de venta la cobertura disponible. Los precios se fijarán en pesos mexicanos (MXN). El SITIO puede ser consultado desde 
                    cualquier parte del mundo, sin embargo, en regiones diferentes a las enunciadas anteriormente estará limitado a consulta informativa. Artículos faltantes: De acuerdo a la 
                    cantidad de artículos solicitados en su pedido, estos pueden enviarse en diferentes paquetes (sin implicar gastos adicionales), en cuyo caso el USUARIO podrá; rastrearlos por 
                    separado, se puede consultar el estatus del envío a través de los medios de atención al cliente dispuestos por <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. mediante la cuenta de usuario 
                    en este SITIO, así como la página correspondiente a la empresa de paquetería seleccionada. Estos datos los recibirá previamente el cliente. En caso de que se envíen pedidos 
                    parciales, se le dará a conocer al USUARIO a través de los medios de contacto proporcionados, por lo que solo se cobrará el importe del producto enviado (y su equivalente en 
                    gastos de envíos). El USUARIO podrá cancelar el pedido pendiente, por lo que <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. Realizará el reembolso. Producto no es el solicitado: El USUARIO 
                    tendrá hasta 7 días hábiles para realizar su solicitud, el producto deberá estar en perfectas condiciones e ir acompañado de todos sus accesorios, documentación (incluida factura 
                    y/o nota), empaques y etiquetas. El USUARIO deberá enviar el artículo a <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong>. a la dirección Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 
                    45500, Tlaquepaque, Jal. El envío corre a cargo del USUARIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> recibirá el producto y realizará un análisis previo al reembolso, para garantizar 
                    la integridad del producto. Cambios por concepto de garantía: El USUARIO tendrá hasta 30 días hábiles a partir de la fecha de recepción del producto para realizar la solicitud de 
                    cambio por garantía, para lo cual el USUARIO deberá comunicarse a través de los canales de contacto disponibles y posteriormente enviar el producto con sus accesorios, 
                    documentación (incluida factura y/o nota), empaques y etiquetas a la dirección Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500, Tlaquepaque, Jal El envío corre a 
                    cargo del USUARIO. <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> recibirá el producto y realizará un análisis para valorar si procede la garantía, en concepto de garantía no existen 
                    reembolsos de ningún tipo. En <strong>PROVEEDOR FERRETERO DE GUADALAJARA</strong> la garantía puede estar extendida a 30 días con nosotros y también directamente en sucursal, transcurrido el 
                    periodo el usuario deberá reclamar la garantía directamente con el fabricante. Consulte con su asesor el periodo de garantía aplicable, en algunos productos presentados como 
                    oferta pueden tener una garantía más limitada, debido a que se presentan como productos en saldo o remate. Los reembolsos se realizarán por el medio más cómodo para el cliente 
                    o a través de nota de crédito para futuras compras en el SITIO. 
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import BannerBlue from '../components/BannerBlue.vue';
export default {
    props:{},
    components:{
        BannerBlue,
    }
}
</script>

<style scoped>
.terminos{
    line-height: 1.5;
    font-family: Arial, sans-serif; 
    color: #333;
    text-align: justify;
    /*font-size: 11px;*/
}
</style>