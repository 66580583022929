import axios from 'axios'

const superApi=axios.create({
    //baseURL:'http://ec2-3-17-66-232.us-east-2.compute.amazonaws.com:8000/',
    baseURL:'http://127.0.0.1:8000/',
    headers: {
        /*"Authorization" : 'Bearer '+ localStorage.getItem('idToken'), */
        'Content-Type': 'application/json',  // Puedes ajustar el tipo de contenido según tus necesidades
    }
})

// Configuración global de Axios para manejar CORS
superApi.defaults.withCredentials = true;

export default superApi