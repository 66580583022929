<template>
    <BannerBlue title="Contáctanos"/>
    <div class="container">
        <div class="container-ubi  mt-5">
            <div class="box-ubi">
                <h3 class="title">Mandanos un correo</h3>
                <div style="padding: 20px;" class="inpform">
                    <InputText id="email" v-model="formEmail.nombre" type="text" placeholder="Nombre" autofocus class=" form-contact-inputs mb-2"/>
                    <InputText id="email" v-model="formEmail.correo" type="email" placeholder="Correo" autofocus class=" form-contact-inputs mb-2"/>
                    <InputText id="email" v-model="formEmail.telefono" type="text" placeholder="Telefono" autofocus class=" form-contact-inputs mb-2"/>
                    <InputText id="email" v-model="formEmail.asunto" type="text" placeholder="Asunto" autofocus class=" form-contact-inputs mb-2"/>
                    <Textarea v-model="formEmail.mensaje" autoResize rows="5" class="form-contact-inputs" placeholder="Mensaje"/>
                    <div class="" style="display: flex; justify-content: end; padding-top: 5px;">
                        <span v-if="errors != ''">{{errors}}</span>
                        <a style="cursor: pointer; color: #000000" class="btn btn-form" @click="validate()">Enviar</a>
                    </div>
                </div>
            </div>
            <div class="box-ubi">
                <h3 class="title">O contáctanos por nuestras redes sociales</h3>
                <div class="box-redesC">
                    <a href="https://wa.me/3315874072" target="_blank" class="outline-buttonC" ><i class="pi pi-whatsapp iconR" style="font-size: 2.6rem; top:50px"></i>&nbsp;</a>
                    <a href="https://www.facebook.com/proveedorferreterogdl/" target="_blank" class="outline-buttonC" ><i class="pi pi-facebook iconR" style="font-size: 2.6rem;"></i>&nbsp;</a>
                    <a href="https://www.instagram.com/proveedorferreterogdl/?igshid=MWZjMTM2ODFkZg%3D%3D" target="_blank" class="outline-buttonC" ><i class="pi pi-instagram iconR" style="font-size: 2.6rem;"></i>&nbsp;</a>
                    <a href="https://www.youtube.com/@proveedorferreterodeguadal8285" target="_blank" class="outline-buttonC" ><i class="pi pi-youtube iconR" style=" font-size: 2.6rem;"></i>&nbsp;</a>
                </div>
            </div>
        </div>
        <Divider align="center" style="background: none;">
            <h5 class="subtitle1" style="background: none;">Sucursales</h5>
        </Divider>
        <div class="container-ubi  mt-2">
            <div class="box-ubi">
                <h5 class="subtitle2 text-center">Matriz</h5>
                <p class="text-start" style="text-align: start;"><strong>Teléfono: </strong> 33 1578 0535 o 33 1578 0536</p>
                <p style="height: 55px; text-align: start;"><strong>Ubicación: </strong> Av. Dr. R. Michell #3101, Col. Álamo Industrial, CP. 45500 Tlaquepaque, Jal.</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d906.9322769834855!2d-103.32344255007399!3d20.62766043998417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b250b7d8b963%3A0x96af54be891db4f9!2sPFG%20Proveedor%20Ferretero%20De%20Guadalajara!5e0!3m2!1ses-419!2smx!4v1693602200896!5m2!1ses-419!2smx" class="maps-ubi" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="box-ubi">
                <h5 class="subtitle2 text-center">PFG Calle 74</h5>
                <p style="text-align: start;"><strong>Teléfonos:</strong> 33 1587 4831 o 33 3860 1348</p>
                <p style="height: 4rem; text-align: start;"><strong>Ubicación:</strong> Av. Dr. Roberto Michel 3101, Alamo Industrial, 45593 San Pedro Tlaquepaque, Jal.</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1525.005529456936!2d-103.3104385231799!3d20.654255719405356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3ccf92b3389%3A0xd8d0a67f2639b0ad!2sProveedor%20Ferretero!5e0!3m2!1ses-419!2smx!4v1693610797331!5m2!1ses-419!2smx" class="maps-ubi" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>  
        </div>
        <div class="container-ubi  mt-5">
            <div class="box-ubi">
                <h5 class="subtitle2 text-center">Sayer Multicolor Niños Heroes</h5>
                <p style="text-align: start;"><strong>Teléfonos:</strong> 33 1587 2063 o 33 2876 4285</p>
                <p style="height: 4rem; text-align: start;"><strong>Ubicación:</strong> Av. Niños Héroes 610, Alamo Industrial, 45593 San Pedro Tlaquepaque, Jal.</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.9689155462456!2d-103.32211932396615!3d20.63012380134941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b367260b29f7%3A0x7a2d01b1701a0748!2sSayer%20Multicolor!5e0!3m2!1ses!2smx!4v1693679077269!5m2!1ses!2smx" class="maps-ubi" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="box-ubi">
                <h5 class="subtitle2 text-center">PFG Calzada</h5>
                <p class="text-start" style="text-align: start;"><strong>Teléfono: </strong> 33 2458 0255 o 33 4357 2506 o 33 4357 2507</p>
                <p style="height: 4rem; text-align: start;"><strong>Ubicación: </strong> Calz Independencia Sur 902, Mexicaltzingo, 44180 Guadalajara, Jal.</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1662.911654446235!2d-103.34865995035868!3d20.665089447984975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b383b1fe0b11%3A0x1f831bf92b35580a!2sProveedor%20Ferretero%20Calzada!5e0!3m2!1ses-419!2smx!4v1693611417041!5m2!1ses-419!2smx" class="maps-ubi" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div class="container-ubi  mt-5">
            <div class="box-ubi">
                <h5 class="subtitle2 text-center">Berel Multicolor Patria</h5>
                <p style="text-align: start;"><strong>Teléfonos:</strong> 33 2458 0261 o 33 1654 8927</p>
                <p style="height: 4rem; text-align: start;"><strong>Ubicación:</strong> Av. Patria 2433, Patria, 44210 Zapopan, Jal. Abajo de Wingman Lagos Country</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d233.24511049732308!2d-103.3682662832535!3d20.71340118022097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2smx!4v1693611724541!5m2!1ses-419!2smx" class="maps-ubi" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="box-ubi">
                <h5 class="subtitle2 text-center">Berel Multicolor Revolución</h5>
                <p class="text-start" style="text-align: start;"><strong>Teléfono: </strong> 33 1941 2960 o 33 1654 8927</p>
                <p style="height: 4rem; text-align: start;"><strong>Ubicación: </strong> Calz. Revolución 2669, Jardines de la Paz, 44860 Guadalajara, Jal.</p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.7462579096036!2d-103.30652977148107!3d20.646172243683353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428b3c9da5cd8e5%3A0x485bf89a1831cdd7!2sPinturas%20Berel-%20Multicolor!5e0!3m2!1ses-419!2smx!4v1693672587291!5m2!1ses-419!2smx" class="maps-ubi" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
    <Divider type="solid"/>
</template>

<script>
import BannerBlue from '@/components/BannerBlue.vue';
import Swal from 'sweetalert2'
import { ApiService } from '@/services/ApiService';
export default {
    props:{},
    components:{
        BannerBlue,
    },
    data(){
        return {
            formEmail: {
                nombre:"",
                correo:"",
                telefono:"",
                asunto:"",
                mensaje:"",
            },
            errors: "",
        }
    },
    methods: {
        validate(){
            // Validar cada campo
            this.errors = "";
            // Definir un array con las propiedades del formulario
            const fields = ['nombre', 'correo', 'telefono', 'asunto', 'mensaje'];
            // Recorrer el array de propiedades
            for (const field of fields) {
                if (!this.formEmail[field]) {
                    this.errors = `El ${field} es obligatorio`;
                    return false;
                }
            }
            // Comprobar si hay errores
            if (Object.keys(this.errors).length > 0) {
                return false; // Hay errores, no enviar el formulario
            }
            this.sendEmail();
        },
        async sendEmail(){
            await ApiService.post('/sendContactEmail', this.formEmail)//Llamamos al Service de las apis para traer datos en get
                .then(async data => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: false,
                        didOpen: (toast) => {
                            toast.onmouseenter = Swal.stopTimer;
                            toast.onmouseleave = Swal.resumeTimer;
                        }
                    });
                    Toast.fire({
                        icon: "success",
                        title: data
                    });
                    this.formEmail.nombre="";
                    this.formEmail.correo="";
                    this.formEmail.telefono="";
                    this.formEmail.asunto="";
                    this.formEmail.mensaje="";
                })
                .catch(error => {
                    console.error('Error en la solicitud:', error.message);
                });
		},
    },
}
</script>

<style scoped>
.btn-form, .btn-form a:hover{
    color: #000;
}
.text-center{
    text-align: center;
}
.iconR{
    color: #1b1b1b;
}
.iconR:hover{
    color: #ffbf00;
}
.inpform textarea{
    background: #fff;
    border: 2px solid #7e7e7e;
    border-radius: 5px;
}
.inpform input{
    background: #fff;
    border-radius: 5px;
    border: 2px solid #7e7e7e;
}

.inpform input:hover{
    border: 2px solid #ffbf00;
}

.inpform textarea:hover{
    border: 2px solid #ffbf00;
}
.box-redesC {
    text-align: left;
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 0 20px;
}

.outline-buttonC {
    display: flex;
    width: auto;
    height: 70px;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #007bff;
    transition: background-color 0.3s, color 0.3s;
    color: #000;
}

.outline-buttonC i {
    font-size: 40px;
}

/*.outline-buttonC:hover {
    background-color: #b68d2c;
    color: #fff;
}*/
.maps-ubi{
    width: 100%;
    height:300px;
}
.form-contact-inputs{
    width: 100%;
}
.container-ubi {
  display: flex;
  justify-content: space-between;
}

.box-ubi{
  width: 50%; /* Distribuir en 3 columnas */
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .container-ubi {
    flex-direction: column; /* Cambiar a columnas */
  }

  .box-ubi{
    width: 100%; /* Ocupar todo el ancho */
    margin-bottom: 10px;
  }
}
</style>