import { createStore } from 'vuex'

export default createStore({
  state: {
    products: [],
  },
  mutations: {
    addProduct(state, product) {
      state.products.push(product);
      localStorage.setItem('jproducts', JSON.stringify(state.products));
    },
    deleteProduct(state, index) {
      if (index >= 0 && index < state.products.length) {
        // Elimina el objeto en la posición especificada
        state.products.splice(index, 1);
        // Actualiza el localStorage después de eliminar
        localStorage.setItem('jproducts', JSON.stringify(state.products));
      }
    },
    updateProductQuantity(state, { outerIndex, quantity }) { //Función de actualización de precio
      state.products[outerIndex].quantity += quantity;//Si encuentra parecido suma las cantidades, la nueva con la vieja
      localStorage.setItem('jproducts', JSON.stringify(state.products));
    },
    setMyVariable(state, nuevoValor) {// Mutación para cambiar el valor del json
      state.products = nuevoValor;
    },
  },
  actions: {
    addProduct({ commit }, product) {//Función para ir agregandolos a la lista
      commit('addProduct', product);
    },
    
    findAndUpdateProduct({ state, commit }, productInfo) {//Función para buscar si ya existe el producto agregado en el carrito.
      const { quantity } = productInfo;
      for (let i = 0; i < state.products.length; i++) {//Ciclo para pasar por toda la lista del carrito
        if (state.products[i].model === productInfo.model && state.products[i].brand === productInfo.brand) {//Verifica si existen iguales la marca y modelo en la lista
          // Si encuentra el producto, actualiza la cantidad
          commit('updateProductQuantity', { outerIndex: i, quantity });//Manda a llamar la función de actualizar con la posiscion en donde se encontró
          return 1;//Termina la función
        }
      }
      // Si no encuentra el producto, agrégalo al array
      commit('addProduct', productInfo);//Llama a la función ara agregar
      return 2;
    },
  },
  getters: {
    getProducts: (state) => state.products,//Función para solicitar los datos del array
  },
});