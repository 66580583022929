<template>
    <div>
        <iframe
        :src="folio"
        width="90%"
        height="800px"
        />
    </div>
</template>

<script>
    export default {
        props:{},
        components:{},
        data(){
            return{
                folio: "https://api.proveedorferretero.net/viewCoti/"
            }
        },
        beforeMount(){
            this.folio = this.folio + this.$route.params.folio;
        },
        mounted(){
        },
        methods:{
            
        }

    }
</script>

<style scoped>

</style>