<template>
    <div class="preloader-cover">
		<!--
			<div class="preloader">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>

		-->
		<div class="loader">
			<img src="../assets/img/logo_carga.gif" alt="" style="width: 240px">
		</div>
	</div>
</template>

<script>
    export default {
        props:{
			loading: Boolean,
		}
    }
</script>

<style scoped>
.preloader-cover {
	display: grid;
	place-content: center;
}
</style>